import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { verifyOTP, ForgotPassword } from '../API/api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import secureLocalStorage from 'react-secure-storage'

const Verification = () => {
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [secondsLeft, setSecondsLeft] = useState(null);
    const [isValidate, setIsValidate] = useState(Number(secureLocalStorage.getItem("isValidate")));
    const [email, setEmail] = useState(localStorage.getItem("email"));
    const [otpEndTime, setOtpEndTime] = useState(null);
    const maxAttempts = 4;
    const [failedAttempts, setFailedAttempts] = useState(
        Number(localStorage.getItem('failedAttempts')) || 0
    );

    const path = window.location.pathname;
    function getPathName() {
        const pathParts = path.split("/");
        const firstPartOfPath = pathParts[1];
        return firstPartOfPath.charAt(0).toUpperCase() + firstPartOfPath.slice(1);
    }
    useEffect(() => {
        document.title = `Towing | ${getPathName()} `;
    }, [path]);
    const timerIntervalRef = useRef(null);

    const startTimer = () => {
        // Clear any existing interval first
        if (timerIntervalRef.current) {
            clearInterval(timerIntervalRef.current);
        }

        let otpEndTime = localStorage.getItem('otpEndTime');

        if (!otpEndTime) {
            const now = Date.now();
            otpEndTime = now + 3 * 60 * 1000;
            localStorage.setItem('otpEndTime', otpEndTime.toString());
        }
        const updateSecondsLeft = () => {
            const timeLeft = (Number(otpEndTime) - Date.now()) / 1000;

            if (timeLeft <= 0) {
                // Time's up!
                setSecondsLeft(0);
                clearInterval(timerIntervalRef.current);
                localStorage.removeItem('otpEndTime');
                localStorage.removeItem('failedAttempts');
                secureLocalStorage.removeItem('isValidate');
                localStorage.removeItem('email');
                navigate('/');
            } else {
                setSecondsLeft(timeLeft);
            }
        };

        updateSecondsLeft();
        timerIntervalRef.current = setInterval(updateSecondsLeft, 1000);
        return () => clearInterval(timerIntervalRef.current);
    };


    const sendEmail = async (e) => {
        e.preventDefault();
        try {
            // Remove old failedAttempts value from local storage
            localStorage.removeItem('failedAttempts');
            const user = await ForgotPassword(email);
            if (user.status == 1) {
                toast.success(user.data);
                setEmail(email);
                secureLocalStorage.setItem("isValidate", 1);
                localStorage.removeItem('otpEndTime');
                setFailedAttempts(0);
                startTimer();
            } else {
                toast.error(user.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    useEffect(() => {
        if (isValidate !== 1) {
            navigate('/');
        } else {
            startTimer();
        }
    }, [isValidate]);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
        return `${mins}:${secs}`;
    };

    const [isInputCleared, setIsInputCleared] = useState(false);
    const handleOtpChange = (e, index) => {
        const { value } = e.target;
        const numericValue = parseInt(value);
        let updatedOtp = [...otp];
        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 9) {
            updatedOtp[index] = value;
            setOtp(updatedOtp);
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1].current.focus();
            }
            setIsInputCleared(false);
        } else if (otp[index] !== '' && !isInputCleared) {
            updatedOtp[index] = '';
            setOtp(updatedOtp);
            setIsInputCleared(true);
        }
    };


    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            const updatedOtp = [...otp];
            if (index > 0 && otp[index] === '') {
                updatedOtp[index - 1] = '';
                setOtp(updatedOtp);
                inputRefs[index - 1].current.focus();
            } else if (otp[index] !== '') {
                updatedOtp[index] = '';
                setOtp(updatedOtp);
            }
        } else if (e.key === 'Enter' && index === otp.length - 1) {
            // Trigger handleOTP when Enter key is pressed on the last input field
            handleOTP(e);
        }
    };

    const handleOTP = async (e) => {
        const enteredOTP = otp.join('');
        try {
            const user = await verifyOTP(enteredOTP, email);
            if (user.status === 1) {
                toast.success(user.message);
                setFailedAttempts(0);
                localStorage.removeItem("otpEndTime");
                localStorage.removeItem('failedAttempts');
                navigate('/newPassword');
            } else {
                toast.error(user.message);
                setFailedAttempts((prevAttempts) => prevAttempts + 1);
                localStorage.setItem('failedAttempts', failedAttempts + 1);
                if (failedAttempts + 1 >= maxAttempts) {
                    toast.error("Too many failed attempts. Please try again later.");
                    localStorage.removeItem("otpEndTime");
                    navigate('/reset-password');
                    localStorage.removeItem('failedAttempts');
                    secureLocalStorage.removeItem('isValidate');
                }
                setOtp(['', '', '', '']);
            }
        } catch (error) {
            toast.error(error);
            setOtp(['', '', '', '']);
        }
    }

    return (
        <Fragment>
            <section className='auth-main d-flex align-items-center justify-content-center'>
                <div className='auth-box'>
                    <div>
                        <img src='./img/logo.svg' width="150px" className='logo' />
                        <div className='auth-heading position-relative my-5'>
                            <h1>Verification</h1>
                        </div>
                    </div>
                    <div className='show-email'>
                        <p>
                            Please, enter the OTP code send to
                        </p>
                        <p>
                            {email}
                        </p>
                    </div>

                    <Form className='user-form mt-4'>
                        <p className='mb-3 timer'>{secondsLeft !== null ? formatTime(secondsLeft) : ''}</p>
                        <div className='input-main-auth verify-code d-flex align-items-center gap-3 justify-content-center'>
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type='text'
                                    value={digit}
                                    onChange={(e) => handleOtpChange(e, index)}
                                    // maxLength="1"
                                    ref={inputRefs[index]}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                            ))}
                        </div>
                        <div>
                            <p className='show-email mt-3'>
                                {maxAttempts - failedAttempts > 1
                                    ? `${maxAttempts - failedAttempts} attempts left`
                                    : maxAttempts - failedAttempts === 1
                                        ? '1 attempt left'
                                        : 'No more attempts left'}
                            </p>
                        </div>


                        <p className='mt-3 auth-footer'>Haven't received the code? <Link to="/" className='d-inline-block' onClick={sendEmail}>  Send it again</Link></p>
                        <Button className='submit-btn' onClick={handleOTP}>Submit</Button>
                    </Form>

                </div>
            </section>
        </Fragment>
    )
}

export default Verification
