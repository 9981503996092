import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";
import NotifyData from "../components/NotifyData";
import { useLocation, useNavigate } from "react-router-dom";


const Notification = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const activeTabFromState = location.state && location.state.activeTab;

  const [activeTab, setActiveTab] = useState(activeTabFromState || 'first');

  const handleAddNotifyClick = () => {
    navigate('/add-notification', { state: { activeTab: activeTab } });
  };
  return (
    <Layout>
      <div className="border-green">
        <div className="py-3 py-sm-4">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                <div className="notify-tab-header d-flex align-content-center justify-content-between gap-3 flex-wrap position-relative">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first" onClick={() => setActiveTab("first")}>Driver</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" onClick={() => setActiveTab("second")}>User</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Button className="add-notify d-flex align-items-center gap-2" onClick={handleAddNotifyClick}>
                    <img src="./img/add.svg" alt="Add" width="12px" />
                    Add
                  </Button>
                </div>
                <div className="p-3 ">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <NotifyData userRole="driver" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <NotifyData userRole="user" />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notification;
