import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Button, Nav, Tab } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateNotification } from "../API/api";
import { toast } from "react-toastify";

const AddNotify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeTabFromState = location.state && location.state.activeTab;
  const [activeTab, setActiveTab] = useState(activeTabFromState || 'first');
  const [message, setMessage] = useState('');


  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  //Add Notification
  const handleSaveNotification = async () => {
    try {
      const userRole = activeTab === 'first' ? 'driver' : 'user';
      if (!message || message.trim() === '') {
        toast.error('Add message');
        return;
      }
      const add = await CreateNotification(message, userRole);
      if (add.status == 1) {
        toast.success(add.message);
        setMessage('');
        navigate('/notification', { state: { activeTab: activeTab } });
      } else {
        toast.error(add.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSaveNotification();
    }
  }


  return (
    <Layout>
      <div className="border-green">
        <div className="py-3 py-sm-4">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                <div className="notify-tab-header d-flex align-content-center justify-content-between gap-3 flex-wrap position-relative">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first" onClick={() => handleTabSelect('first')}>Driver</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" onClick={() => handleTabSelect('second')}>User</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="p-3 ">
                  <Tab.Content>
                    <Tab.Pane eventKey="first" userRole="driver">
                      <div className="notify-input">
                        <label>Message</label>
                        <textarea rows={10} placeholder="Enter Message...." name="message" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => handleKeyPress(e)}>
                        </textarea>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button className="save-btn" onClick={handleSaveNotification}>Save</Button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" userRole="user">
                      <div className="notify-input">
                        <label>Message</label>
                        <textarea rows={10} placeholder="Enter Message..." name="message" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => handleKeyPress(e)}></textarea>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button className="save-btn" onClick={handleSaveNotification}>Save</Button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddNotify;
