import React, { useContext, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import FeesData from "../components/FeesData";
import { TowContext } from "../context/context";
import { GetCountry } from "../API/api";
import { toast } from "react-toastify";

const FeesDetails = () => {
  const towContext = useContext(TowContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCountry, setLocalCountry, getCountryData, getCountry } = towContext;
  const initialCountry = location.state?.country || selectedCountry;
  const [country, setCountry] = useState(initialCountry);


  const onCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
  };

  useEffect(() => {
    // console.log("Country updated:", country);
  }, [country]);

  useEffect(() => {
    setCountry(selectedCountry);
  }, [selectedCountry]);


  useEffect(() => {
    getCountryData();
  }, [])

  useEffect(() => {
    if (getCountry.length > 0) {
      // Check if the selected country is in the list
      const countryExists = getCountry.some(c => c.country_name === initialCountry);

      // Set the country to the selectedCountry if it exists in the list, otherwise, set it to the first country
      setCountry(countryExists ? initialCountry : getCountry[0].country_name);
    }
  }, [getCountry, initialCountry]);

  return (
    <Layout>
      <div className="border-green">
        <div className="p-3 p-sm-4">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                <select className="search-option" onChange={(e) => onCountryChange(e.target.value)} value={country} >
                  {getCountry.map((c, index) => {
                    return <option>{c.country_name}</option>
                  })}
                </select>
                <Button
                  className="fees-btn d-flex align-items-center gap-2 green-btn"
                  onClick={() => {
                    setLocalCountry(country);
                    navigate("/add-fees", { state: { country } })
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
          <FeesData country={country} />
        </div>
      </div>
    </Layout>
  );
};

export default FeesDetails;
