import React, { useContext, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import DriverData from "../components/DriverData";
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-toastify'
import { GetThreshold, driverRegister } from "../API/api";
import { validatePassword } from "../Helper/validation";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { TowContext } from "../context/context";

const DriverDetails = () => {
  const [searchData, setSearchData] = useState('');
  const [add, setAdd] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone_no, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState("");
  const [deposit_amount, setDeposit] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [matchPassword, setMatchPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [perPage, setPerPage] = useState(10);
  const [showPass, setShowPass] = useState();
  const [showCPass, setShowCPass] = useState();
  const [isError, setIsError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [country, setCountry] = useState('');
  const { getCountry, getCountryData } = useContext(TowContext);
  const [threshold_value, setThresholdValue] = useState('');
  let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const handleChange = async (e) => {
    setSearchData(e.target.value.toLowerCase());
  }
  const handleAddClose = () => {
    setAdd(false);
  }
  const handleAddShow = () => {
    setAdd(true);
    getThresholdValue(country)
  }

  const matchPasswords = () => {
    if (
      password &&
      cPassword &&
      password === cPassword
    ) {
      setMatchPassword(true);
    } else {
      setMatchPassword(false);
    }
  };

  useEffect(() => {
    matchPasswords();
  }, [cPassword, password]);

  const handleChangeDeposit = (e) => {
    const inputValue = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/; // Regex to allow only up to 2 digits after the decimal point

    if (regex.test(inputValue)) {
      if (inputValue !== "" && parseFloat(inputValue) < threshold_value) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setDeposit(inputValue);
    }
  };


  const handleAdd = async () => {
    try {

      if (!name) {
        toast.error("Please fill name");
        return;
      }
      if (!email) {
        toast.error("Please fill email");
        return;
      }
      if (!password) {
        toast.error("Please fill password");
        return;
      }
      if (!cPassword) {
        toast.error("Please fill confirm password");
        return;
      }
      if (!phone_no) {
        toast.error("Please fill phone no");
        return;
      }
      if (isError == true) {
        return;
      }
      if (phoneError == true) {
        return;
      }
      if (!deposit_amount) {
        toast.error("Please add deposit");
        setIsError(true);
        return;
      } else {
        setIsError(false); // Reset the error state

        if (deposit_amount < threshold_value) {
          toast.error(`Minimum Deposit amount is ${threshold_value}`);
          setIsError(true);
          return;
        }
      }


      const addData = await driverRegister(name, email, password, phone_no, country, deposit_amount)
      if (addData.status == 1) {
        setName('');
        setEmail('');
        setPhone('');
        setPassword('');
        setCPassword('');
        setDeposit('');
        toast.success(addData.message);
        setAdd(false);
        DriverData.getDrivers(perPage, currentPage, searchData);
      } else {
        toast.error(addData.message);
      }

    } catch (error) {
      toast.error(error);
    }
  }

  const onCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
  };
  useEffect(() => {
  }, [country]);

  useEffect(() => {
    if (getCountry.length > 0) {
      setCountry(getCountry[0].country_name);
    }
  }, [getCountry]);

  const getThresholdValue = async (selectedCountry) => {
    try {
      const getData = await GetThreshold(selectedCountry);
      if (getData.status == 1) {
        setThresholdValue(getData.data.threshold_value);
      }
    } catch (error) {
      toast.error(error);
    }
  }
  // useEffect(() => {
  //   getThresholdValue(country)
  // }, [country])

  useEffect(() => {
    getCountryData();
  }, [])



  const handlePhoneChange = (value, country) => {
    const countryCode = country.dialCode;
    const countryCodeLength = countryCode?.length;
    const phoneNumberWithoutCountryCode = value.substring(countryCodeLength);

    const numericPhoneNumber = phoneNumberWithoutCountryCode.replace(/\D/g, '');

    if (numericPhoneNumber.length < 6 || numericPhoneNumber.length > 13) {
      setPhoneError(true);
    } else {
      setPhoneError(false);

      let fullPhoneNumber = "+" + countryCode + " " + numericPhoneNumber;
      if (value.startsWith('+')) {
        fullPhoneNumber = value;
      }

      setPhone(fullPhoneNumber); // Update the phone number state
    }
  };

  return (
    <Layout>
      <div className="border-green">
        <div className="p-3 p-sm-4">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search Email/Driver Name" style={{ maxWidth: "400px" }}
                  name="searchData"
                  value={searchData}
                  onChange={handleChange}
                />
                <Button className="fees-btn d-flex align-items-center gap-2" onClick={handleAddShow}>
                  <img src="./img/add.svg" alt="Add" width="12px" />
                  Add
                </Button>
              </div>
            </div>
          </div>
          <DriverData search={searchData} add={add} />
        </div>
      </div>

      <Modal
        show={add}
        onHide={handleAddClose}
        centered
        scrollable
        className="edit-user">
        <Modal.Body>
          <div className="col-10 col-md-8 m-auto">
            <h4 className='border-bottom border-dark text-center pb-3 fw-bold'>Add Driver</h4>
          </div>
          <form action="" className='mt-5 px-2 px-md-4'>
            <div className='mb-3'>
              <label className='fw-bold mb-2'>Full Name</label>
              <input type="text" className='search-input py-2 border input-heght' placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='mb-3'>
              <label className='fw-bold mb-2'>Email</label>
              <input type="email" className='search-input py-2 border input-heght' placeholder='Email' value={email} onChange={(e) => {
                setEmail(e.target.value)
                if (!emailRegex.test(e.target.value))
                  setEmailError(true);
                else setEmailError(false);
              }} />
              {emailError && (
                <div className='validation-message'>
                  Invalid Email.
                </div>
              )}
            </div>
            <div className='mb-3' >
              <label className='fw-bold mb-2'>Password</label>
              <div className="password-main">
                <input
                  type={showPass ? ' text' : 'password'}
                  className='search-input py-2 border input-heght'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setIsPasswordValid(validatePassword(e.target.value));
                  }}
                />
                <Button
                  className='input-button'
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? (
                    <img src='./img/show-pass.svg' width="25px" alt="Show Password" />
                  ) : (
                    <img src='./img/hide-pass.svg' width="25px" alt="Hide Password" />
                  )}
                </Button>
              </div>

              {password && !isPasswordValid && (
                <p className="text-danger text-start inner-alerts">
                  Password requirements:
                  <br />
                  Minimum 8 characters, cannot contain spaces
                  <br />
                  <b>Must include</b>: special characters, numbers, lower case and
                  upper case letters
                </p>
              )}
            </div>

            <div className='mb-3'>
              <label className='fw-bold mb-2'>Confirm Password</label>
              <div className="password-main">
                <input type={showCPass ? ' text' : 'password'} className='search-input py-2 border input-heght' placeholder='Confirm Password' value={cPassword} onChange={(e) => setCPassword(e.target.value)} />
                <Button className='input-button' onClick={() => setShowCPass(!showCPass)}>
                  {
                    showCPass ?
                      <img src='./img/show-pass.svg' width="25px" />
                      :
                      <img src='./img/hide-pass.svg' width="25px" />
                  }
                </Button>
              </div>
              {password &&
                cPassword &&
                (matchPassword ? (

                  <p className="text-success text-start inner-alerts mt-2">
                    ✅ Passwords Matched
                  </p>

                ) : (
                  <p className="text-danger text-start inner-alerts mt-2">
                    ❌ Password does not match
                  </p>
                ))}
            </div>
            <div className='mb-3 phone_no'>
              <label className='fw-bold mb-2'>Phone No</label>
              <PhoneInput
                enableSearch={true}
                enableLongNumbers={true}
                value={phone_no}
                onChange={(value, country) => { handlePhoneChange(value, country) }}
              />
              {phoneError && (
                <div className='validation-message'>
                  Please enter a valid phone number between 6 to 13 digits.
                </div>
              )}
            </div>
            <div className=" mb-3">
              <label className='fw-bold mb-2'>Select Country</label>
              <div className=" ">
                <select className="search-option py-2 border input-heght search-input" value={country} onChange={(e) => onCountryChange(e.target.value)}>
                  {getCountry.map((c, index) => {
                    return <option>{c.country_name}</option>
                  })}
                </select>

              </div>
            </div>
            <div className='mb-3'>
              <label className='fw-bold mb-2'>Deposit</label>
              <input type="text" className='search-input py-2 border input-heght' placeholder='Deposit' value={deposit_amount} onChange={handleChangeDeposit} />
              {isError && (
                <div className='validation-message'>
                  Deposit should be at least {threshold_value}
                </div>
              )}
            </div>

            <div className="d-flex align-items-center gap-3 mt-4 px-2">
              <Button className="save-btn" onClick={handleAddClose}>
                Cancel
              </Button>
              <Button className="save-btn" style={{ width: "75px" }} onClick={handleAdd} >Save</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default DriverDetails;
