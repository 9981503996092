import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import SpinnerComp from "./Spinner";
import { GetWalletById } from "../API/api";
import { useLocation } from "react-router-dom";
import PaginationComponent from "./Pagination";

const UserWalletData = ({ search }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const startIndex = (currentPage - 1) * perPage + 1;
    const { state } = useLocation();
    const selectedId = state && state.selectedId;

    const getWallet = async (perPage, page, search, id) => {
        try {
            const wallet = await GetWalletById(perPage, page, search, id);
            if (wallet.status === 1) {
                setData([...wallet.data]);
                if (wallet.data.length < perPage) {
                    setHasMoreData(false);
                } else {
                    setHasMoreData(true);
                }
                setTotalPage(Math.ceil(wallet.total_transaction / perPage));
            } else {
                setHasMoreData(false);
                setData([])
            }
            setLoading(false);
        } catch (error) {
            // console.error("Error fetching users:", error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (search) {
            getWallet(perPage, 1, search, selectedId);
        }
        else {
            getWallet(perPage, currentPage, null, selectedId);
        }
    }, [search, selectedId, currentPage]);


    const handlePageChange = (pageNumber) => {
        setLoading(true);
        setCurrentPage(pageNumber);
        if (search) {
            getWallet(perPage, pageNumber, search, selectedId);
        } else {
            getWallet(perPage, pageNumber, null, selectedId);
        }
    };



    return (
        <>
            <Table responsive bordered className="mb-0">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Bonus Amount</th>
                        <th>Transaction Type</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <SpinnerComp />
                    ) : (
                        <>
                            {data && data.length > 0 ? (
                                data.map((wallet, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{startIndex + index}</td>
                                            <td>{wallet?.transaction_history?.amount || '-'}</td>
                                            <td>{wallet?.transaction_history?.type || '-'}</td>
                                            <td>{
                                                new Date(wallet?.transaction_history?.date).toLocaleString("en-US", {
                                                    year: "numeric",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                    hour: "2-digit",
                                                    minute: "2-digit"
                                                })
                                            }</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </Table>

            {totalPage > 1 && (
                <PaginationComponent
                    currentPage={currentPage}
                    totalPage={totalPage}
                    handlePageChange={handlePageChange}
                    setPerPage={setPerPage}
                    perPage={perPage}
                />
            )}
        </>
    )
}

export default UserWalletData
