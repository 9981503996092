import React, { useState, useEffect } from "react";
import { Button, OverlayTrigger, Pagination, Table, Tooltip } from "react-bootstrap";
import SpinnerComp from "./Spinner";
import { GetAllWallet } from "../API/api";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "./Pagination";

const WalletData = ({ search }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const startIndex = (currentPage - 1) * perPage + 1;
    const navigate = useNavigate();
    const [id, setId] = useState(null);


    const getWallet = async (perPage, page, search) => {
        try {
            const wallet = await GetAllWallet(perPage, page, search);
            if (wallet.status == 1) {
                setData([...wallet.data]);
                if (wallet.data.length < perPage) {
                    setHasMoreData(false);
                } else {
                    setHasMoreData(true);
                }
                setTotalPage(Math.ceil(wallet.total_count / perPage));
            }
            setLoading(false);
        } catch (error) {
            // console.error("Error fetching users:", error);
            setLoading(false);
        }
    }

    useEffect((e) => {
        if (search) {
            getWallet(perPage, 1, search);
        }
        else {
            getWallet(perPage, currentPage);
        }
    }, [search]);

    const handlePageChange = (pageNumber) => {
        setLoading(true);
        // setCurrentPage(pageNumber);
        getWallet(perPage, pageNumber, search)
    };


    const tooltipEdit = (
        <Tooltip id="tooltip" className="custom-tooltip">Info</Tooltip>
    );

    const handleClick = (userId) => {
        navigate("/user-wallet", { state: { selectedId: userId } });
        setId(userId);
    }
    return (
        <>
            <Table responsive bordered className="mb-0">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Driver</th>
                        <th>Wallet Amount</th>
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <SpinnerComp />
                    ) : (
                        <>
                            {data && data.length > 0 ? (
                                data.map((bonus, index) => (
                                    <tr key={index}>
                                        <td>{startIndex + index}</td>
                                        <td>{bonus?.driver_id?.name || '-'}</td>
                                        <td>{bonus?.deposit_amount || '-'}</td>
                                        <td>{
                                            new Date(bonus.created_at).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit"
                                            })
                                        }</td>
                                        <td>
                                            <div className="user-tools d-flex align-items-center gap-2 justify-content-center">
                                                <OverlayTrigger placement="bottom" overlay={tooltipEdit}>
                                                    <Button onClick={() => handleClick(bonus._id)}>
                                                        <img src="./img/profile/info.svg" alt="" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </Table>

            {totalPage > 1 && (
                <PaginationComponent
                    currentPage={currentPage}
                    totalPage={totalPage}
                    handlePageChange={handlePageChange}
                    setPerPage={setPerPage}
                    perPage={perPage}
                />
            )}
        </>
    )
}

export default WalletData