import axios from "axios";
import moment from "moment";
const COMMON_ERROR = "Error, Please try again.!";
axios.defaults.baseURL = `${process.env.REACT_APP_PUBLIC_URL}`;
console.log(process.env.REACT_APP_PUBLIC_URL, '------------');


// const setAuthToken = () => {
//     const token = localStorage.getItem("token");
//     const AuthToken = token ? `Bearer ${token}` : "";
//     axios.defaults.headers.Authorization = AuthToken;
// };
// setAuthToken();

const token = localStorage.getItem("token");
const AuthToken = token ? `Bearer ${token}` : "";
axios.defaults.headers.Authorization = AuthToken;




const errorResponse = (error) => {
    if (error.response) {
        const { status, data } = error.response;
        if (status === 403) {
            setTimeout(() => {
                window.location.href = "/";
                localStorage.clear();
            }, 5000);
        }
        return data;
    } else {
        return { status: 0, message: COMMON_ERROR };
    }
};

// sign-in
export const LogIn = async (email, password, fcmToken) => {
    try {
        const res = await axios.post(`/admin/sign-in`, {
            email: email,
            password: password,
            fcmToken: fcmToken
        });
        // console.log("=====res", res.data);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const adminRegister = async (name, email, password) => {
    try {
        const res = await axios.post(`/admin/sign-up`, {
            name,
            password,
            email
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const ForgotPassword = async (email) => {
    try {
        const res = await axios.post(`/admin/send-email`, { email: email });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const verifyOTP = async (otp, email) => {
    try {
        const res = await axios.post(`/admin/verify-otp`, {
            otp,
            email
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const newPassword = async (new_password, email) => {
    try {
        const res = await axios.post(`/admin/set-password`, {
            new_password,
            email
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const SignOut = async () => {
    try {
        const res = await axios.post(`/admin/sign-out`);
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetAll = async (perPage, page, searchData) => {
    try {
        if (searchData) {
            const res = await axios.post(`/admin/get-all/${perPage}/${page}?searchData=${searchData}`);
            return res.data;
        } else {
            const res = await axios.post(`/admin/get-all/${perPage}/${page}`);
            return res.data;
        }

    } catch (error) {
        return errorResponse(error);
    }
}

export const GetAllUsers = async (perPage, page, searchData, user_role) => {
    try {
        // if (searchData) {
        const res = await axios.post(`/admin/get-all-user/${perPage}/${page}?searchData=${searchData}`, { user_role });
        return res.data;
        // } else {
        //     const res = await axios.post(`/admin/get-all-user/${perPage}/${page}`, { user_role });
        //     return res.data;
        // }

    } catch (error) {
        return errorResponse(error);
    }
}

export const getUserById = async (userId) => {
    try {
        const res = await axios.post(`/admin/get-user-id/${userId}`);
        // console.log("res id", userId);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const deleteUserById = async (userId) => {
    try {
        const res = await axios.post(`/admin/delete-user-id/${userId}`);
        // console.log("res.id", userId);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
export const deleteDriverById = async (driverId) => {
    try {
        const res = await axios.post(`/admin/delete-driver-id/${driverId}`);
        // console.log("res.id", driverId);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetAllDrivers = async (perPage, page, searchData, user_role) => {
    try {
        // if (searchData) {
        const res = await axios.post(`/admin/get-all-driver/${perPage}/${page}?searchData=${searchData}`, { user_role });
        return res.data;
        // } else {
        //     const res = await axios.post(`/admin/get-all-driver/${perPage}/${page}`, { user_role });
        //     return res.data;
        // }

    } catch (error) {
        return errorResponse(error);
    }
}

export const GetDrivers = async () => {
    try {
        const res = await axios.post(`/admin/get-drivers`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getDriversById = async (userId) => {
    try {
        const res = await axios.post(`/admin/get-driver-id/${userId}`);
        // console.log("res id", userId);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const towStatus = async (id, ride_type, country) => {
    try {
        const res = await axios.post(`/admin/ride-type/${id}`, {
            ride_type: ride_type,
            country: country
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const towStatusShow = async (id) => {
    try {
        const res = await axios.post(`/admin/ride-type-show/${id}`);
        // console.log("res", res.data);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
export const documentStatusShow = async (id) => {
    try {
        const res = await axios.post(`/admin/document-verified-show/${id}`);
        // console.log("res", res.data);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const documentVerification = async (userId, document_verification) => {
    try {
        const res = await axios.post(`/admin/document-verified/${userId}`, {
            document_verification: document_verification
        });
        return res.data
    } catch (error) {
        return errorResponse(error)
    }
}

export const editProfile = async (data) => {
    try {
        const res = await axios.post(`/admin/edit-profile`, data);
        // console.log("edit res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getProfile = async () => {
    try {
        const res = await axios.get('/admin/get-profile');
        // console.log("get res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getTotals = async () => {
    try {
        const res = await axios.get(`/admin/get-total`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getDriverTotals = async () => {
    try {
        const res = await axios.post(`/admin/get-driver-total`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getAllTrip = async (perPage, page, searchData, selectedStatus) => {
    try {
        const url = `/trip/get-all-trip/${perPage}/${page}`;

        if (searchData && selectedStatus && selectedStatus !== "All") {
            const res = await axios.post(`${url}?searchData=${searchData}&selectedStatus=${selectedStatus}`);
            console.log("res", res.data);
            return res.data;
        } else if (searchData) {
            const res = await axios.post(`${url}?searchData=${searchData}`);
            return res.data;
        } else if (selectedStatus && selectedStatus !== "All") {
            const res = await axios.post(`${url}?selectedStatus=${selectedStatus}`);
            return res.data;
        } else {
            const res = await axios.post(url);
            return res.data;
        }
    } catch (error) {
        return errorResponse(error);
    }
}

export const getFixFees = async (country) => {
    try {
        const res = await axios.post(`/admin/get-fixed-fee`, {
            country: country
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getDynamicFees = async (country) => {
    try {
        const res = await axios.post(`/admin/get-dynamic-fee`, {
            country: country
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getFeesDetails = async (perPage, page, country) => {
    try {
        const res = await axios.post(`/admin/get-fees/${perPage}/${page}`, {
            country: country
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const addMinFees = async (country, other_class_minimum) => {
    try {
        const res = await axios.post(`/admin/set-minimum-fee`, {
            country,
            other_class_minimum
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const addAdminFees = async (country, admin_fee_percent, admin_min_charge) => {
    try {
        const res = await axios.post(`/admin/set-admin-share`, {
            country,
            admin_fee_percent,
            admin_min_charge
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}


export const addFixFees = async (country, currency, processing_fee, handling_fee, meter, otherClass) => {
    try {
        const res = await axios.post(`/admin/set-fixed-fee`, {
            country,
            currency,
            processing_fee,
            handling_fee,
            meter,
            otherClass
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        errorResponse(error);
    }
}

export const addDynamicFees = async (start_date,
    end_date,
    start_time,
    end_time,
    start_day,
    end_day,
    decrement,
    increment,
    country) => {
    const [s_year, s_month, s_date] = start_date.split("-");
    const [e_year, e_month, e_date] = end_date.split("-");
    const [s_hours, s_minutes] = start_time.split(":");
    const [e_hours, e_minutes] = end_time.split(":");
    var starting_date = new Date(
        s_year,
        s_month - 1,
        s_date,
        s_hours,
        s_minutes,
        0
    );
    starting_date = moment(starting_date).utc().format();
    var ending_date = new Date(
        e_year,
        e_month - 1,
        e_date,
        e_hours,
        e_minutes,
        0
    );
    ending_date = moment(ending_date).utc().format();

    try {
        const res = await axios.post(`/admin/set-dynamic-fee`, {
            start_date,
            end_date,
            start_time,
            end_time,
            start_day,
            end_day,
            decrement,
            increment,
            starting_date,
            ending_date,
            country
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const addCoupon = async (title, code, amount, percentage, minimum_amount, exp_date, description, discount_type) => {
    try {
        const res = await axios.post(`/admin/create-coupon`, {
            title: title,
            code: code,
            amount: amount,
            percentage: percentage,
            minimum_amount: minimum_amount,
            exp_date: exp_date,
            description: description,
            discount_type: discount_type
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const EditCoupon = async (id, title, code, exp_date, amount, percentage, minimum_amount, description, discount_type) => {
    try {
        const res = await axios.post(`/admin/update-coupon/${id}`, {
            title: title,
            code: code,
            exp_date: exp_date,
            amount: amount,
            percentage: percentage,
            minimum_amount: minimum_amount,
            description: description,
            discount_type: discount_type
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getCoupon = async (perPage, page) => {
    try {
        const res = await axios.get(`/admin/get-coupons/${perPage}/${page}`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getCouponsById = async (couponId) => {
    try {
        const res = await axios.post(`/admin/get-coupon-by-id/${couponId}`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const disableCoupon = async (couponId, disable) => {
    try {
        const res = await axios.post(`/admin/disable-coupon/${couponId}`, {
            disable
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const deleteCoupon = async (couponId) => {
    try {
        const res = await axios.post(`/admin/delete-coupon/${couponId}`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const disableAccount = async (accountId, disable_account) => {
    try {
        const res = await axios.post(`/admin/disable-account/${accountId}`, {
            disable_account
        });
        // console.log(res, "res----------------------");
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
export const disableAccountAdmin = async (accountId, admin_flag, disable_account) => {
    try {
        const res = await axios.post(`/admin/disable-account-admin/${accountId}`, {
            admin_flag, disable_account
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const CreateNotification = async (message, user_role,) => {
    try {
        const res = await axios.post('/admin/create-notification-message', {
            message: message,
            user_role: user_role
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetAllNotification = async (perPage, page, user_role) => {
    try {
        const res = await axios.post(`/admin/get-notification/${perPage}/${page}`, { user_role });
        return res.data;

    } catch (error) {
        return errorResponse(error);
    }
}

export const DeleteNotification = async (notifyId) => {
    try {
        const res = await axios.post(`/admin/delete-notification/${notifyId}`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetTow = async (country) => {
    try {
        const res = await axios.post(`/admin/get-tow`, {
            country: country
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
export const GetActiveTow = async (country) => {
    try {
        const res = await axios.post(`/admin/get-active-tow`, {
            country: country
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
export const CreateTow = async (title, country, image) => {
    try {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('country', country);
        formData.append('image', image);

        const response = await axios.post(`/admin/create-tow`, formData);
        // console.log("response", formData);
        return response.data;
    } catch (error) {
        return errorResponse(error);
    }
};

export const UpdateTow = async (title, country, image, newType) => {
    try {
        const formData = new FormData();
        formData.append('type', title);
        formData.append('country', country);
        formData.append('image', image);
        formData.append('newType', newType);
        const response = await axios.post(`/admin/update-tow-image`, formData)
        // console.log("response", formData);
        return response.data;
    } catch (error) {
        return errorResponse(error);
    }
};

export const DeleteTow = async (type, country) => {
    try {
        const res = await axios.post(`/admin/delete-tow`, {
            country: country,
            type: type
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const DisableTow = async (country, rideClass, is_active) => {
    try {
        const res = await axios.post(`/admin/disable-tow`, {
            country: country,
            rideClass: rideClass,
            is_active: is_active
        });
        // console.log("res", res);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetMessage = async (sender_user, receiver_user) => {
    try {
        const res = await axios.post(`/users/get-message/${sender_user}/${receiver_user}`);
        // console.log("res", res.data);
        return res.data
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetAllBonus = async (perPage, page, searchData) => {
    try {
        if (searchData) {
            const res = await axios.post(`/admin/get-bonus-all/${perPage}/${page}?searchData=${searchData}`);
            return res.data;
        } else {
            const res = await axios.post(`/admin/get-bonus-all/${perPage}/${page}`);
            return res.data;
        }
    } catch (error) {
        return errorResponse(error);
    }
}

export const AddBonus = async (email, title, amount) => {
    try {

        const res = await axios.post(`/admin/add-bonus/`, {
            email: email,
            title: title,
            amount: amount
        });
        return res.data;

    } catch (error) {
        return errorResponse(error);
    }
}

export const GetAllWallet = async (perPage, page, searchData) => {
    try {
        if (searchData) {
            const res = await axios.post(`/admin/get-wallet-details/${perPage}/${page}?searchData=${searchData}`);
            // console.log("res", res);
            return res.data;
        } else {
            const res = await axios.post(`/admin/get-wallet-details/${perPage}/${page}`);
            // console.log("res", res);
            return res.data;
        }
    } catch (error) {
        return errorResponse(error);
    }
}

export const GetWalletById = async (perPage, page, searchData, userId) => {
    try {
        if (searchData) {
            const res = await axios.post(`/admin/get-wallet-by-id/${perPage}/${page}/${userId}?searchData=${searchData}`);

            return res.data;
        } else {
            const res = await axios.post(`/admin/get-wallet-by-id/${perPage}/${page}/${userId}`);
            // console.log("res", userId);
            return res.data;
        }
    } catch (error) {
        return errorResponse(error);
    }
}

export const AddThreshold = async (threshold_value, country) => {
    try {
        const res = await axios.post(`/admin/set-threshold`, {
            threshold_value: threshold_value,
            country: country
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
};

export const GetThreshold = async (country) => {
    try {
        const res = await axios.post(`/admin/get-threshold`, {
            country: country
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
};

export const GetCountry = async (perPage, page) => {
    try {
        const res = await axios.post(`/admin/get-country/${perPage}/${page}`);
        return res.data
    } catch (error) {
        return errorResponse(error);
    }
}

export const AddCountryData = async (countryName) => {
    try {
        const res = await axios.post(`/admin/add-country`, {
            country_name: countryName
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const DeleteCountry = async (id) => {
    try {
        const res = await axios.post(`/admin/delete-country/${id}`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
export const GetDeposit = async (perPage, page) => {
    try {
        const res = await axios.post(`/admin/get-wallet-details/${perPage}/${page}`);
        return res.data
    } catch (error) {
        return errorResponse(error);
    }
}

export const driverRegister = async (name, email, password, phone_no, country, deposit_amount) => {
    try {
        const res = await axios.post(`/admin/add-driver`, {
            name,
            password,
            email,
            phone_no,
            country,
            deposit_amount
        });
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}


export const driverEdit = async (id, userData) => {
    try {
        const formData = new FormData();

        // Append non-empty and non-undefined fields to formData
        Object.entries(userData).forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
                formData.append(key, value);
            }
        });

        const response = await axios.post(`/admin/edit-driver/${id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (error) {
        return errorResponse(error);
    }
};


export const GetTripById = async (id) => {
    try {
        const res = await axios.post(`/trip/get-trip/${id}`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const disableDriver = async () => {
    try {
        const res = await axios.post(`/admin/disable-all-drivers`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getCountries = async () => {
    try {
        const res = await axios.get(`/admin/get-countries`);
        return res.data;
    } catch (error) {
        return errorResponse(error);
    }
}
