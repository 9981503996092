import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, NavLink } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { LogIn } from '../API/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { messaging } from '../firebase/firebaseConfig';
import secureLocalStorage from 'react-secure-storage'
import { getToken } from 'firebase/messaging'

const SignIn = () => {

    const [showPass, setShowPass] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [fcmToken, setFcmToken] = useState('');

    const navigate = useNavigate();
    const location = useLocation()
    const path = window.location.pathname;
    function getPathName() {

        const pathParts = path.split("/");
        const firstPartOfPath = pathParts[1];
        if (firstPartOfPath === '/' || !firstPartOfPath || firstPartOfPath === '') {
            return "Sign In";
        }
        else {
            return firstPartOfPath.charAt(0).toUpperCase() + firstPartOfPath.slice(1);
        }
    }
    useEffect(() => {
        document.title = `Towing | ${getPathName()} `;
    }, [path]);

    const adminLogin = async (e) => {
        try {
            const user = await LogIn(email, password, fcmToken);
            console.log("user", user);
            if (!email || !password) {
                toast.error("email and password are required");
            } else {
                if (user.status == 1) {
                    localStorage.setItem("token", user.token);
                    localStorage.setItem("fcmToken", user.fcmToken);
                    localStorage.setItem("email", `${email}`);
                    localStorage.setItem("login", true);
                    localStorage.setItem("id", user.data._id);
                    localStorage.setItem("name", user.data.first_name);
                    localStorage.removeItem('otpEndTime');
                    localStorage.removeItem('email');
                    secureLocalStorage.removeItem("isValidate");
                    toast.success(user.message);
                    setTimeout(() => {
                        window.location.reload("/dashboard");
                    }, 1000);

                } else {
                    toast.error(user.message);
                }
            }
        }
        catch (error) {
            toast.error(error);
        }
    }
    // console.log("process.env.REACT_APP_VAPID", process.env.REACT_APP_VAPID);

    useEffect(() => {
        const getFCMToken = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const fcmToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID });

                    if (fcmToken) {
                        // console.log('FCM Token:', fcmToken);
                        setFcmToken(fcmToken);
                    } else {
                        console.log('Unable to get FCM token.');
                    }
                } else {
                    console.log('Permission for notifications denied.');
                }
            } catch (error) {
                console.error('Error requesting permission for notifications:', error);
            }
        };

        getFCMToken();
    }, []);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            adminLogin();
        }
    };


    return (
        <Fragment>
            <section className='auth-main d-flex align-items-center justify-content-center'>
                <div className='auth-box'>
                    <div>
                        <img src='./img/logo.svg' width="150px" className='logo' />
                        <div className='auth-heading position-relative my-5'>
                            <h1>SIGN IN</h1>
                        </div>
                    </div>

                    <Form className='user-form'>
                        <div className='input-main-auth'>
                            <label>Email</label>
                            <input type='email' placeholder='Enter Email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='input-main-auth mt-4'>
                            <label>Password</label>
                            <div className='position-relative'>
                                <input type={showPass ? ' text' : 'password'} placeholder='Enter Password' name="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress} />
                                <Button className='show-pass' onClick={() => setShowPass(!showPass)}>
                                    {
                                        showPass ?
                                            <img src='./img/show-pass.svg' width="25px" />
                                            :
                                            <img src='./img/hide-pass.svg' width="25px" />
                                    }
                                </Button>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <Link to="/reset-password" className='forgot-pass'>Forgot Password?</Link>
                            </div>
                        </div>
                        <Button className='submit-btn' onClick={adminLogin} >Sign in</Button>

                    </Form>

                </div>
            </section>
        </Fragment>
    )
}

export default SignIn
