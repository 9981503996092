import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TooltipComponent = ({ triggerElement, tooltipContent, placement }) => {

    const overlay = (
        <Tooltip id="tooltip">
            {tooltipContent}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            trigger="hover"
            placement={placement || "bottom"}
            overlay={overlay}
        >
            {React.cloneElement(triggerElement)}
        </OverlayTrigger>
    );
};

export default TooltipComponent;
