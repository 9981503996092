import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const ToastyComp = () => {
  return (
      <div>
          <ToastContainer />
    </div>
  )
}

export default ToastyComp