import React, { useState, useEffect } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { AiOutlineMenuUnfold } from 'react-icons/ai'
import { SignOut } from '../API/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { useLocation } from 'react-router-dom'
import { getProfile } from '../API/api'
const Header = (props) => {

    const navigate = useNavigate();
    const [email, setEmail] = useState(localStorage.getItem("email"));
    const [name, setName] = useState(localStorage.getItem("name"));
    const location = useLocation()
    const [title, setTitle] = useState("");

    const handleEdit = async () => {
        navigate('/edit-profile');
    }

    const handleLogout = async () => {
        try {
            const user = await SignOut();
            // console.log("user", user);
            if (user.status === 1) {
                localStorage.clear();
                localStorage.setItem("signOut", true);
                toast.success(user.message);
                window.location.replace('/');
            } else {
                toast.error(user.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }
    useEffect(() => {
        // Update the title based on the current location.pathname
        if (location.pathname === '/dashboard') {
            setTitle('Dashboard');
        }
        if (location.pathname === '/user-details') {
            setTitle('User Details');
        }
        if (location.pathname === '/driver-details') {
            setTitle('Driver Details');
        }
        if (location.pathname === '/edit-driver') {
            setTitle('Edit Driver Details');
        }
        if (location.pathname === '/trip-details') {
            setTitle('Trip Details');
        }
        if (location.pathname === '/notification' || location.pathname === '/add-notification') {
            setTitle('Notification Details');
        }
        if (location.pathname === '/fees-details') {
            setTitle('Fees Details');
        }
        if (location.pathname === '/coupon') {
            setTitle('Coupon Details');
        }
        if (location.pathname === '/class') {
            setTitle('Tow Class Details');
        }
        if (location.pathname === '/add-country') {
            setTitle('Country Details');
        }
        if (location.pathname === '/bonus-details') {
            setTitle('Bonus Details');
        }
        if (location.pathname === '/wallet-details') {
            setTitle('Wallet Details');
        }
        if (location.pathname === '/contact') {
            setTitle('Contact Support');
        }
        if (location.pathname === '/edit-profile') {
            setTitle('Hello Admin');
        }
        if (location.pathname === '/add-fees') {
            setTitle('Add Fee Details');
        }
        if (location.pathname === '/add-coupon') {
            setTitle('Add Coupon Details');
        }
        if (location.pathname === '/configure') {
            setTitle('Configuration');
        }
    }, [location.pathname]);
    return (
        <header className='header'>
            <div className='d-flex align-items-center justify-content-between'>
                <h5>{title}</h5>
                <div className='d-flex align-items-center gap-3'>
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                            <img alt='' src='./img/header/profile.svg' className='user-img' />
                            <span>Hello, {name}</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className='d-flex align-items-center gap-3' onClick={handleEdit}>
                                <span><img alt='' src='./img/sidebar/user.svg' width="20px" /></span>
                                My Profile
                            </Dropdown.Item>
                            <Dropdown.Item className='d-flex align-items-center gap-3' onClick={handleLogout}>
                                <span><img alt='' src='./img/sidebar/logout.svg' width="18px" /></span>
                                Log Out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button className='toggle-btn' onClick={() => props.setToggle(!props.toggle)}><AiOutlineMenuUnfold /></Button>
                </div>
            </div>
        </header>
    )
}

export default Header
