import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { SignOut } from '../API/api'
import { toast } from 'react-toastify'


const Sidebar = (props) => {

    const location = useLocation()
    const [active, setActive] = useState(location.pathname);

    const path = window.location.pathname;
    function getPathName() {
        const pathParts = path.split("/");
        const firstPartOfPath = pathParts[1];
        return firstPartOfPath.charAt(0).toUpperCase() + firstPartOfPath.slice(1);
    }

    useEffect(() => {
        console.log("getPathName()", getPathName());

        document.title = `Towing | ${getPathName()} `;
    }, [path]);

    setTimeout(() => {
        SignOut();
        localStorage.clear();
        localStorage.setItem("signOut", true);
        window.location.replace('/');
    }, 7200000)

    const handleLogout = async () => {
        try {
            const user = await SignOut();
            // console.log("user", user);
            if (user.status === 1) {
                localStorage.clear();
                localStorage.setItem("signOut", true);
                toast.success(user.message);
                window.location.replace('/');
            } else {
                toast.error(user.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }
    return (
        <div className={`${props.toggle ? "m-0" : ""} sidebar`}>
            <div className=' text-center py-5'>
                <Link className={`${active === "/dashboard" ? "active" : ""}`} to="/dashboard" onClick={() => setActive("/dashboard")} >
                    <img alt='' src='./img/logo.svg' width="120px" />
                </Link>
            </div>
            <div className='menu'>
                <ul>
                    <li>
                        <Link className={`${active === "/dashboard" || active === "/edit-profile" ? "active" : ""} `} to="/dashboard" onClick={() => setActive("/dashboard")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/dashboard.svg' width="19px" />
                            </span>
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/user-details" className={`${active === "/user-details" ? "active" : ""}`} onClick={() => setActive("/user-details")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/user.svg' width="19px" />
                            </span>
                            User Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/driver-details" className={`${active === "/driver-details" || active === "/edit-driver" ? "active" : ""}`} onClick={() => setActive("/driver-details")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/driver.svg' width="19px" />
                            </span>
                            Driver Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/trip-details" className={`${active === "/trip-details" ? "active" : ""}`} onClick={() => setActive("/trip-details")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/trip.svg' width="19px" />
                            </span>
                            Trip Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/notification" className={`${active === "/notification" || active === "/add-notification" ? "active" : ""}`} onClick={() => setActive("/notification")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/notification.svg' width="19px" />
                            </span>
                            Notification
                        </Link>
                    </li>
                    <li>
                        <Link to="/fees-details" className={`${active === "/fees-details" || active === "/add-fees" ? "active" : ""}`} onClick={() => setActive("/fees-details")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/fees.svg' width="19px" />
                            </span>
                            Fee Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/coupon" className={`${active === "/coupon" || active === "/add-coupon" ? "active" : ""}`} onClick={() => setActive("/coupon")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/coupon.svg' width="19px" />
                            </span>
                            Coupon Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/class" className={`${active === "/class" || active === "/class" ? "active" : ""}`} onClick={() => setActive("/class")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/tow.svg' width="19px" />
                            </span>
                            Tow class
                        </Link>
                    </li>
                    <li>
                        <Link to="/add-country" className={`${active === "/add-country" || active === "/add-country" ? "active" : ""}`} onClick={() => setActive("/add-country")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/country.svg' width="19px" />
                            </span>
                            Country Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/bonus-details" className={`${active === "/bonus-details" || active === "/bonus-details" ? "active" : ""}`} onClick={() => setActive("/bonus-details")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/bonus.svg' width="19px" />
                            </span>
                            Bonus Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/wallet-details" className={`${active === "/wallet-details" || active === "/user-wallet" ? "active" : ""}`} onClick={() => setActive("/wallet-details")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/wallet.svg' width="19px" />
                            </span>
                            Wallet Details
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" className={`${active === "/contact" || active === "/contact" ? "active" : ""}`} onClick={() => setActive("/contact")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/contact.svg' width="19px" />
                            </span>
                            Contact Support
                        </Link>
                    </li>
                    <li>
                        <Link to="/configure" className={`${active === "/configure" || active === "/configure" ? "active" : ""}`} onClick={() => setActive("/configure")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/configure.svg' width="19px" />
                            </span>
                            Configuration
                        </Link>
                    </li>
                    <li>
                        <NavLink onClick={handleLogout}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/logout.svg' width="19px" />
                            </span>
                            Log Out
                        </NavLink>
                    </li>
                    <li>
                        <Link to="/delete-account" className={`${active === "/delete-account" || active === "/delete-account" ? "active" : ""}`} onClick={() => setActive("/delete-account")}>
                            <span className='menu-icon'>
                                <img alt='' src='../img/sidebar/configure.svg' width="19px" />
                            </span>
                            Delete Account
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar
