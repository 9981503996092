import React, { useContext, useEffect, useState } from 'react'
import Layout from "../layout/Layout";
import { Button, Col, Row } from 'react-bootstrap';
import { AddThreshold, GetThreshold, disableDriver } from '../API/api';
import { toast } from 'react-toastify'
import { TowContext } from '../context/context';

const Configuration = () => {
    const [country, setCountry] = useState('');
    const [threshold_value, setThresholdValue] = useState('');
    const [editMinimum, setEditMinimum] = useState(false);
    const { getCountry, getCountryData } = useContext(TowContext);

    const onCountryChange = (selectedCountry) => {
        setCountry(selectedCountry);
    };
    useEffect(() => {
    }, [country]);

    useEffect(() => {
        if (getCountry.length > 0) {
            setCountry(getCountry[0].country_name);
        }
    }, [getCountry]);

    const getThresholdValue = async (selectedCountry) => {
        try {
            const getData = await GetThreshold(selectedCountry);
            if (getData.status == 1) {
                setThresholdValue(getData.data.threshold_value);
            }
        } catch (error) {
            toast.error(error);
        }
    }
    useEffect(() => {
        getThresholdValue(country)
    }, [country])

    //Add ThresholdValue
    const handleSubmit = async () => {
        try {
            const saveData = await AddThreshold(threshold_value, country);
            if (saveData.status == 1) {
                toast.success(saveData.message);
                setEditMinimum(false);
                disableDriver();
            } else {
                toast.error(saveData.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }
    useEffect(() => {
        getCountryData();
    }, [])

    return (
        <Layout>
            <div className="border-green">
                <div className="p-3 py-sm-4 px-0">
                    <div className="row mb-3">
                        <div className="col-lg-12 col-sm-12">
                            <div className="notify-tab-header d-flex align-content-center justify-content-between gap-3 flex-wrap">
                                <select className="search-option search-option-pdding mb-3 mx-4 px-4" onChange={(e) => onCountryChange(e.target.value)}>
                                    {getCountry.map((c, index) => {
                                        return <option>{c.country_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <Row className='mx-4 g-0'>
                        <Col lg={3} md={3} sm={12}>
                            <div className="fees-input mt-3">
                                <label className='dark-font mb-2 fw-bold'>Minimum Deposit Balance</label>
                                <input type="number" placeholder="Enter Minimum Deposit Balancet" value={threshold_value} name='threshold_value' onChange={(e) => setThresholdValue(e.target.value)} className={editMinimum ? "dark-font" : ""} readOnly={!editMinimum} />
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex gap-3 mx-4 ">
                        {
                            editMinimum ? (
                                <Button className="save-btn py-1 w-auto fs-7 " onClick={handleSubmit} >Update</Button>
                            ) : (
                                <Button
                                    className="save-btn py-1 w-auto fs-7 "
                                    onClick={() => {
                                        setEditMinimum(true);
                                    }}
                                >
                                    Save Minimum Deposit Balance
                                </Button>
                            )
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Configuration
