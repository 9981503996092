import React, { Fragment, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignIn from './auth/SignIn';
import Register from './auth/Register';
import NewPassword from './auth/NewPassword'

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css'
import Dashboard from './page/Dashboard';
import ResetPass from './auth/ResetPass';
import Verification from './auth/Verification';
import Toasty from './components/ToastyComp';
import EditProfile from './page/EditProfile';
import UserDetail from './page/UserDetail';
import DriverDetails from './page/DriverDetails';
import TripDetails from './page/TripDetails';
import Notification from './page/Notification';
import FeesDetails from './page/FeesDetails';
import Coupon from './page/Coupon';
import AddNotify from './page/AddNotify';
import AddFees from './page/AddFees';
import AddCoupon from './page/AddCoupon';
import Class from './page/Class';
import BonusDetail from './page/BonusDetail';
import WalletDetail from './page/WalletDetail';
import UserWallet from './page/UserWallet';
import Configuration from './page/Configuration';
import PrivateRoute from './components/PrivateRoute';
import { Navigate } from 'react-router-dom';
import Error from './page/error';
import PrivateRouteLogin from './components/PrivateRouteLogin';
import Contact from './page/Contact';
import AddCountry from './page/AddCountry';
import EditDriver from './page/EditDriver';
import { TowContext } from './context/context';
import DeleteAccount from './page/DeleteAccount';
const App = () => {

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered', registration);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:=======', error);
        });
    }
  }, []);

  const { disableDriver } = TowContext;
  return (
    <Fragment>
      <Toasty />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<PrivateRouteLogin><SignIn /></PrivateRouteLogin>} />
          {/* <Route path='/register' element={<Register />} /> */}
          <Route path='/reset-password' element={localStorage.getItem('login') ? <Navigate to="/dashboard" /> : <ResetPass />} />
          <Route path='/verification' element={<Verification />} />
          <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path='/newPassword' element={<NewPassword />} />
          <Route path='/edit-profile' element={<PrivateRoute><EditProfile /></PrivateRoute>} />
          <Route path='/user-details' element={<PrivateRoute><UserDetail /></PrivateRoute>} />
          <Route path='/driver-details' element={<PrivateRoute><DriverDetails /></PrivateRoute>} />
          <Route path='/edit-driver' element={<PrivateRoute><EditDriver /></PrivateRoute>} />
          <Route path='/trip-details' element={<PrivateRoute><TripDetails /></PrivateRoute>} />
          <Route path='/notification' element={<PrivateRoute><Notification /></PrivateRoute>} />
          <Route path='/add-notification' element={<PrivateRoute><AddNotify /></PrivateRoute>} />
          <Route path='/fees-details' element={<PrivateRoute><FeesDetails /></PrivateRoute>} />
          <Route path='/add-fees' element={<PrivateRoute><AddFees /></PrivateRoute>} />
          <Route path='/coupon' element={<PrivateRoute><Coupon /></PrivateRoute>} />
          <Route path='/add-coupon' element={<PrivateRoute><AddCoupon /></PrivateRoute>} />
          <Route path='/contact' element={<PrivateRoute><Contact /></PrivateRoute>} />
          <Route path='/class' element={<PrivateRoute><Class /></PrivateRoute>} />
          <Route path='/bonus-details' element={<PrivateRoute><BonusDetail /></PrivateRoute>} />
          <Route path='/wallet-details' element={<PrivateRoute><WalletDetail /></PrivateRoute>} />
          <Route path='/user-wallet' element={<PrivateRoute><UserWallet /></PrivateRoute>} />
          <Route path='/configure' element={<PrivateRoute><Configuration /></PrivateRoute>} />
          <Route path='/add-country' element={<PrivateRoute><AddCountry /></PrivateRoute>} />
          <Route path='/delete-account' element={<DeleteAccount />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  )
}

export default App
