import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { GetAllUsers, getUserById, deleteUserById, disableAccount } from "../API/api";
import { toast } from 'react-toastify'
import SpinnerComp from "./Spinner";
import TooltipComponent from "./TooltipComponent";
import PaginationComponent from "./Pagination";

const UserData = ({ search }) => {

  const [edit, setEdit] = useState(false);
  const [userData, setUserData] = useState({});
  const [id, setId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(true);
  const startIndex = (currentPage - 1) * perPage + 1;

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  const handleEditClose = () => setEdit(false);

  const handleEditShow = async (userId) => {
    try {
      const userInfo = await getUserById(userId);
      setUserData(userInfo.data);
      setEdit(true);
    } catch (error) {
      toast.error('Error fetching user data', error);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  }


  const handleDeleteModalShow = async (userId) => {
    setDeleteModal(true);
    setId(userId);
  }
  const handleDelete = async (userId) => {
    try {
      const deleteUser = await deleteUserById(userId);
      if (deleteUser.status == 1) {
        toast.success(deleteUser.message);
        setData((prevData) => prevData.filter((user) => user._id !== userId));
        setDeleteModal(false);
        if (data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        } else {
          getUsers(perPage, currentPage);
        }
      } else {
        toast.error(deleteUser.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }


  const handleAccountChange = async (accountId, status) => {
    try {
      const newUsersData = data.map((user) =>
        user._id === accountId
          ? {
            ...user,
            disable_account: !user.disable_account,
          }
          : user
      );

      setData(newUsersData);
      const disableUser = await disableAccount(accountId, !status);
      if (disableUser.status === 1) {
        // console.log("disable", disableUser);
        if (disableUser.disable_account == true) {
          toast.success("User Blocked");
        } else {
          toast.success('User Unblocked');
        }
      } else {
        toast.error(disableUser.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getUsers = async (perPage, page, search) => {
    try {
      const user = await GetAllUsers(perPage, page, search, 'user');
      if (user.status === 1) {
        const usersData = user.data.map((user) => ({
          ...user,
          disable_account: user.disable_account,
        }));
        setData(usersData);
        if (user.data.length < perPage) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
        setTotalPage(Math.ceil(user.total_user / perPage));
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (search) {
    //   getUsers(perPage, 1, search);
    // } else {
    getUsers(perPage, currentPage, search);
    // }
  }, [search]);


  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    getUsers(perPage, pageNumber, search);
  };


  const defaultImageSrc = "./img/user.jpg";
  const profileImageSrc = userData.profile_image ? baseUrl + '/' + userData.profile_image : defaultImageSrc;

  return (
    <>
      <Table responsive bordered className="mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Created at</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <SpinnerComp />
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{startIndex + index}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone_no}</td>
                      <td>{
                        new Date(user.created_at).toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit"
                        })
                      }</td>
                      <td>
                        <div className="status">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={!user.disable_account}
                              onChange={() => handleAccountChange(user._id, user.disable_account)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="user-tools d-flex align-items-center gap-2 justify-content-center">
                          <TooltipComponent
                            triggerElement={
                              <Button onClick={() => handleEditShow(user._id)}>
                                <img src="./img/profile/info.svg" alt="" />
                              </Button>
                            }
                            tooltipContent="Info"
                          />
                          <TooltipComponent
                            triggerElement={
                              <Button onClick={() => handleDeleteModalShow(user._id)}>
                                <img src="./img/profile/delete.svg" alt="" />
                              </Button>
                            }
                            tooltipContent="Delete User"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      {totalPage > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPage={totalPage}
          handlePageChange={handlePageChange}
          setPerPage={setPerPage}
          perPage={perPage}
        />
      )}

      <Modal
        show={edit}
        onHide={handleEditClose}
        centered
        scrollable
        className="edit-user"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center position-relative">
            <h5
              className="modal-title-text modal-border pb-4 text-center"
              style={{ width: "400px" }}
            >
              User Details
            </h5>
            <Button className="close-modal" onClick={handleEditClose}>
              <IoMdClose />
            </Button>
          </div>
          <div className="user-data mt-4">
            <div className="d-flex align-content-center justify-content-center gap-4 item-center">
              <div className="profile_image">
                <img src={profileImageSrc} width="80px" height="80px" style={{ borderRadius: "50%" }} />
              </div>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Name :</h5>
              <p>{userData.name}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>ID :</h5>
              <p>{userData._id}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Role :</h5>
              <p>{userData.user_role}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Email :</h5>
              <p>{userData.email}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Phone No. :</h5>
              <p>{userData.phone_no}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Registered Through :</h5>
              <p className="transform">{userData.social_media}</p>
            </div>

            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Registration At :</h5>
              <p className="font" >{
                new Date(userData.created_at).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit"
                })
              }</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Last updated :</h5>
              <p>{
                new Date(userData.updated_at).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit"
                })
              }</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={handleDeleteModalClose}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Delete User
            </h5>
          </div>
          <div className="text-center mt-4">
            <p className="my-2">Are you sure you want to delete this user?</p>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleDeleteModalClose}>
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={() => handleDelete(id)}>Delete User</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserData;
