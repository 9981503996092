import React, { useEffect, useState } from "react";
import { Pagination, Table, Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { GetAllNotification, DeleteNotification } from "../API/api";
import { toast } from "react-toastify";
import SpinnerComp from "./Spinner";
import TooltipComponent from "./TooltipComponent";
import PaginationComponent from "./Pagination";

const NotifyData = ({ userRole }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(true);
  const startIndex = (currentPage - 1) * perPage + 1;

  const notification = async (perPage, page) => {
    const notificationData = await GetAllNotification(perPage, page, userRole);
    console.log("data", notificationData);
    if (notificationData.status = 1) {
      setData(notificationData.data);
      if (notificationData.data.length < perPage) {
        setHasMoreData(false);
      } else {
        setHasMoreData(true);
      }
      setTotalPage(Math.ceil(notificationData.total_user / perPage));
    }
    setLoading(false);
  }
  // console.log("data", data);

  useEffect(() => {
    notification(perPage, currentPage);
  }, [currentPage]);

  const handlePageChange = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    await notification(perPage, pageNumber);
  };

  const handleDeleteModalClose = async () => {
    setDeleteModal(false);
    // toast.error("Cancel User");
  }
  const handleDeleteModalShow = async (notifyId) => {
    setDeleteModal(true);
    setId(notifyId);
    // console.log("notifyId", notifyId);
  }


  const handleDelete = async (notifyId) => {
    try {
      const deleteNotify = await DeleteNotification(notifyId);
      // console.log("deleteNotify", deleteNotify);
      if (deleteNotify.status == 1) {
        toast.success(deleteNotify.message);
        setData((prevData) => prevData.filter((user) => user._id !== notifyId));
        setDeleteModal(false);
        if (data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        } else {
          notification(perPage, currentPage);
        }
      } else {
        toast.error(deleteNotify.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }


  return (
    <>
      <Table responsive bordered className="mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th>Notification</th>
            <th>Created at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <SpinnerComp />
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((n, index) => {
                  return (
                    <tr key={index}>
                      <td>{startIndex + index}</td>
                      <td className="wrap-text">
                        {n.message}
                      </td>
                      <td>
                        <p>{
                          new Date(n.created_at).toLocaleString("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit"
                          })
                        }</p>
                        <p>{n.time}</p>
                      </td>
                      <td>
                        <div className="user-tools d-flex align-items-center gap-2 justify-content-center">
                          <TooltipComponent
                            triggerElement={
                              <Button onClick={() => handleDeleteModalShow(n._id)}>
                                <img src="./img/profile/delete.svg" alt="" />
                              </Button>
                            }
                            tooltipContent="Delete Notification"
                          />
                        </div>
                      </td>
                    </tr>
                  )
                })

              ) : (
                <tr>
                  <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                </tr>
              )}</>
          )}


        </tbody>
      </Table>

      {totalPage > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPage={totalPage}
          handlePageChange={handlePageChange}
          setPerPage={setPerPage}
          perPage={perPage}
        />
      )}


      <Modal
        show={deleteModal}
        onHide={handleDeleteModalClose}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Delete Notification
            </h5>
          </div>
          <div className="text-center mt-4">
            <p className="my-2">Are you sure you want to delete this notification?</p>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleDeleteModalClose}>
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={() => handleDelete(id)}>Delete Notification</Button>
              {/* {console.log(id, data, "data._id")} */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotifyData;
