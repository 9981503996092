import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { Button } from "react-bootstrap";
import { editProfile, getProfile } from "../API/api";
import { toast } from 'react-toastify'

const EditProfile = () => {
  const [userData, setUserData] = useState({ name: "", first_name: "", email: "", last_name: "", address: "", city: "", country: "", postal_code: "", about_me: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');

  const navigate = useNavigate();
  const updateLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const handleInputChange = (event, fieldName) => {
    const { value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: value,
    }));

    if (fieldName === 'postal_code') {
      const postalCodeRegex = /^[0-9]{6}$/;
      if (!postalCodeRegex.test(value)) {
        setPostalCodeError('Postal code must be a 6-digit number.');
      } else {
        setPostalCodeError('');
      }
    } else if (fieldName === 'name') {
      if (value.trim() === '') {
        setNameError('Username is required.');
      } else {
        setNameError('');
      }
    }
    else if (fieldName === 'last_name') {
      if (value.trim() === '') {
        setLastNameError('Last name is required.');
      } else {
        setLastNameError('');
      }
    } else if (fieldName === 'first_name') {
      if (value.trim() === '') {
        setFirstNameError('First name is required.');
      } else {
        setFirstNameError('');
      }
    }

  };

  const handleEditButtonClick = async () => {
    if (isEditing) {
      try {
        if (postalCodeError) {
          return;
        }
        if (nameError) {
          return;
        }
        if (lastNameError) {
          return;
        }
        if (firstNameError) {
          return;
        }
        const response = await editProfile(userData);

        if (response.status === 1) {
          // Update userData with the response data
          setUserData(response.data);
          toast.success(response.message);
          updateLocalStorage("name", response.data.first_name);
          setIsEditing(false);
          handleData();
          navigate('/dashboard');
        } else {
          toast.error(response.message);
          setIsEditing(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      setIsEditing(true);
    }
  };

  const handleData = async () => {
    const user = await getProfile();
    setUserData(user);
  }
  useEffect(() => {
    handleData();
  }, []);


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission behavior
      handleEditButtonClick();
    }
  };

  return (
    <Layout>
      <div className="top-def">
        <p>
          This is your profile page. You can see the progress you've made with your work and manage your projects or assigned tasks.
        </p>
      </div>

      <div className='border-green mt-4'>
        <div className='profile-title p-3 p-sm-4 d-flex align-items-center justify-content-between'>
          <h5>My Account</h5>
          <Button onClick={handleEditButtonClick}> {isEditing ? 'Save Profile' : 'Edit Profile'}</Button>
        </div>
        <h5 className='dashboard-title font-cos py-3 px-3 px-sm-4'>User Information:</h5>
        <div className='p-3 pt-sm-0 pt-0 p-sm-4'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>Username</label>
                <input
                  type='text'
                  value={isEditing ? userData.name : userData?.data?.name}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'name')}
                  placeholder="Enter username"
                  className={isEditing ? 'bold-text' : ''}
                />
                {nameError && (
                  <div className='validation-message'>
                    {nameError}
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>Email address</label>
                <input
                  type='email'
                  value={isEditing ? userData.email : userData?.data?.email}
                  readOnly={true}
                  onChange={(e) => handleInputChange(e, 'email')}
                  placeholder="Enter email"
                />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>First name</label>
                <input
                  type='text'
                  value={isEditing ? userData.first_name : userData?.data?.first_name}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'first_name')}
                  placeholder="Enter firstname"
                  className={isEditing ? 'bold-text' : ''}
                />
                {firstNameError && (
                  <div className='validation-message'>
                    {firstNameError}
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>Last name</label>
                <input
                  type='text'
                  value={isEditing ? userData.last_name : userData?.data?.last_name}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'last_name')}
                  placeholder="Enter lastname"
                  className={isEditing ? 'bold-text' : ''}
                />
                {lastNameError && (
                  <div className='validation-message'>
                    {lastNameError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <h5 className='dashboard-title font-cos py-3 px-3 px-sm-4'>Contact Information:</h5>
        <div className='p-3 p-sm-4 pt-sm-0 pt-0'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>Address</label>
                <input
                  type='text'
                  value={isEditing ? userData.address : userData?.data?.address}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'address')}
                  placeholder="Enter address"
                  className={isEditing ? 'bold-text' : ''}
                />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>City</label>
                <input
                  type='text'
                  value={isEditing ? userData.city : userData?.data?.city}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'city')}
                  placeholder="Enter city"
                  className={isEditing ? 'bold-text' : ''}
                />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>Country</label>
                <input
                  type='text'
                  value={isEditing ? userData.country : userData?.data?.country}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'country')}
                  placeholder="Enter country"
                  className={isEditing ? 'bold-text' : ''}
                />
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-12'>
              <div className='input-filed mt-3'>
                <label>Postal code</label>
                <input
                  type='text'
                  value={isEditing ? userData.postal_code : userData?.data?.postal_code}
                  readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'postal_code')}
                  placeholder="Enter postal code"
                  pattern="[0-9]{6}"
                  className={isEditing ? 'bold-text' : ''}
                />
                {postalCodeError && (
                  <div className='validation-message'>{postalCodeError}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <h5 className='dashboard-title font-cos py-3 px-3 px-sm-4'>About me:</h5>
        <div className='p-3 p-sm-4 pt-sm-0 pt-0'>
          <div className='row'>
            <div className='col-12'>
              <div className='input-filed mt-3'>
                <label>About Me</label>
                <textarea type='text' placeholder='Enter About you' rows='5' value={isEditing ? userData.about_me : userData?.data?.about_me} readOnly={!isEditing}
                  onChange={(e) => handleInputChange(e, 'about_me')}
                  className={isEditing ? 'bold-text' : ''}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditProfile;
