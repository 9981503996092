// src/firebase/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCiaTG-zg5DwWBntCyrVX7M_SSoeWsKdbs",
    authDomain: "remoexpress-admin.firebaseapp.com",
    projectId: "remoexpress-admin",
    storageBucket: "remoexpress-admin.appspot.com",
    messagingSenderId: "1038166792259",
    appId: "1:1038166792259:web:a4e7d9c85a2d609e3d9cf6",
    measurementId: "G-QTD9DX8J1W"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export { messaging }