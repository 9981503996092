import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function SpinnerComp() {
    return (
        <tr>
            <td colSpan="10" style={{ fontSize: 'large' }}><Spinner animation="border" variant="success" /></td>
        </tr>
    )
}

export default SpinnerComp
