import { Navigate, useLocation } from 'react-router-dom';

function PrivateRouteLogin({ children, ...rest }) {
    let location = useLocation();

    let isLoggedIn = !!localStorage.getItem('token');

    return !isLoggedIn ? children : <Navigate to="/dashboard" state={{ from: location }} replace />;
}

export default PrivateRouteLogin;
