import React, { useContext, useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { getDriversById, driverEdit, GetCountry } from '../API/api'
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { TowContext } from '../context/context';
function EditDriver() {
    const { state } = useLocation();
    const selectedId = state && state.selectedId;

    const defaultImageUrl = "./img/document.jpg";
    const baseUrl = process.env.REACT_APP_PUBLIC_URL;
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isError1, setIsError1] = useState(false);
    const [isError2, setIsError2] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const pattern = new RegExp(/^\d{1,10}$/);
    const [country, setCountry] = useState('');
    const { getCountry, getCountryData } = useContext(TowContext);
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        deposit_amount: "",
        phone_no: "",
        address: "",
        country: "",
        driving_licence_front: "",
        driving_licence_back: "",
        insurance_front: "",
        insurance_back: "",
        vehicle_image_front: "",
        vehicle_image_back: "",
        vehicle_RC_front: "",
        vehicle_RC_back: ""
    });



    const handleData = async () => {
        const user = await getDriversById(selectedId);
        setUserData(user);
    }
    useEffect(() => {
        handleData();
    }, []);


    const handleEditButtonClick = async () => {

        try {
            // Pass only the fields that have been modified to driverEdit function
            const modifiedFields = {
                name: userData.name,
                email: userData.email,
                deposit_amount: userData.deposit_amount,
                phone_no: userData.phone_no,
                address: userData.address,
                country: userData.country,
                driving_licence_front: userData.driving_licence_front,
                driving_licence_back: userData.driving_licence_back,
                insurance_front: userData.insurance_front,
                insurance_back: userData.insurance_back,
                vehicle_image_front: userData.vehicle_image_front,
                vehicle_image_back: userData.vehicle_image_back,
                vehicle_RC_front: userData.vehicle_RC_front,
                vehicle_RC_back: userData.vehicle_RC_back,
            };
            if (isError == true) {
                return;
            }
            if (isError1 == true) {
                toast.error("Invalid file format")
                return;
            }
            if (phoneError == true) {
                return;
            }

            const response = await driverEdit(selectedId, modifiedFields);

            if (response.status === 1) {
                setUserData((prevUserData) => ({
                    ...prevUserData,
                    ...response.data,
                }));

                toast.success(response.message);
                setIsEditing(false);
                handleData();
            } else {
                toast.error(response.message);
                setIsEditing(false);
            }
        } catch (error) {
            toast.error(error);
        }

    };


    const handleInputChange = (event, fieldName, country) => {
        const { value } = event.target;
        if (fieldName === 'phone_no') {
            if (country && country.dialCode) {
                const countryCode = country.dialCode;
                const countryCodeLength = countryCode.length;
                const phoneNumber = value.substring(countryCodeLength).trim();
                const formattedValue = `+${countryCode} ${phoneNumber}`;

                setUserData(prevUserData => ({
                    ...prevUserData,
                    [fieldName]: formattedValue
                }));

                // Validate phone number length
                if (phoneNumber.length < 6 || phoneNumber.length > 13) {
                    setPhoneError(true); // Set phone number error if the length is invalid
                } else {
                    setPhoneError(false); // Clear phone number error if the length is valid
                }
            }
        } else {
            setUserData(prevUserData => ({
                ...prevUserData,
                [fieldName]: value.trim() === '' ? null : value
            }));
        }
    };

    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    const handleFileChange = (event, fileKey) => {
        const file = event.target.files[0];

        // Check if the selected file is of the allowed file types
        if (file && allowedFileTypes.includes(file.type)) {
            setUserData((prevUserData) => ({
                ...prevUserData,
                [fileKey]: file,
            }));
            setIsError1(false);
        } else {
            setUserData((prevUserData) => ({
                ...prevUserData,
                [fileKey]: "", // Clear the invalid file
            }));
            setIsError1(true);
        }
    };

    // useEffect(() => {
    // }, [country]);


    // useEffect(() => {
    //     getCountryData();
    // }, [country])


    return (
        <Layout>
            <div className='border-green mt-4'>
                <div className='profile-title p-3 p-sm-4 d-flex align-items-center justify-content-between'>
                    <h5>Driver Account Details</h5>
                    <Button onClick={handleEditButtonClick}>
                        Update
                    </Button>
                </div>
                <h5 className='dashboard-title font-cos py-3 px-3 px-sm-4'>Driver Information:</h5>
                <div className='p-3 pt-sm-0 pt-0 p-sm-4'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Driver Name</label>
                                <input
                                    type='text'
                                    placeholder="Enter username"
                                    value={userData.name ? userData.name : userData?.data?.name}
                                    onChange={(e) => handleInputChange(e, 'name')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Id</label>
                                <input
                                    type='text'
                                    placeholder="Enter Id"
                                    value={userData.data?._id}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Role</label>
                                <input
                                    type='text'
                                    placeholder="Enter Role"
                                    value={userData.data?.user_role}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Email</label>
                                <input
                                    type='text'
                                    placeholder="Enter email"
                                    value={userData.data?.email}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Deposit Amount</label>
                                <input
                                    type='number'
                                    placeholder="Enter deposit amount"
                                    value={userData.deposit_amount !== null ? userData.deposit_amount : userData?.data?.deposit_amount}
                                    onChange={(e) => handleInputChange(e, 'deposit_amount')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <h5 className='dashboard-title font-cos py-3 px-3 px-sm-4'>Contact Information:</h5>
                <div className='p-3 p-sm-4 pt-sm-0 pt-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3 phone'>
                                <label>Phone No</label>
                                <PhoneInput
                                    // country={'us'}
                                    enableSearch={true}
                                    enableLongNumbers={true}
                                    value={userData.phone_no ? userData.phone_no : userData?.data?.phone_no}
                                    onChange={(value, country) => handleInputChange({ target: { value } }, 'phone_no', country)}
                                />
                            </div>
                            {phoneError && (
                                <div className='validation-message'>
                                    Please enter a valid phone number between 6 to 13 digits.
                                </div>
                            )}
                        </div>
                        {/* <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Phone No</label>
                                <input
                                    type='text'
                                    placeholder="Enter Phone no"
                                    value={userData.phone_no ? userData.phone_no : userData?.data?.phone_no}
                                    onChange={(e) => {
                                        handleInputChange(e, 'phone_no')
                                        if (!pattern.test(e.target.value))
                                            setIsError(true);
                                        else setIsError(false);
                                    }
                                    }
                                />
                                {isError && (
                                    <div className='validation-message'>
                                        Invalid Phone No.
                                    </div>
                                )}
                            </div>
                        </div> */}
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Address</label>
                                <input
                                    type='text'
                                    placeholder="Enter address"
                                    value={userData.address ? userData.address : userData?.data?.address}
                                    onChange={(e) => handleInputChange(e, 'address')}
                                />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Country</label>
                                {/* <input
                                    type='text'
                                    placeholder="Enter country"
                                    value={userData.country ? userData.country : userData?.data?.country}
                                    onChange={(e) => handleInputChange(e, 'country')}
                                /> */}
                                <select className="search-input " value={userData.country ? userData.country : userData?.data?.country} onChange={(e) => handleInputChange(e, 'country')}>
                                    {getCountry.map((c, index) => {
                                        return <option>{c.country_name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Register Through</label>
                                <input
                                    type='text'
                                    placeholder="Enter .."
                                    value={userData.data?.social_media}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <h5 className='dashboard-title font-cos py-3 px-3 px-sm-4'>Upload Document:</h5>
                <div className='p-3 p-sm-4 pt-sm-0 pt-0'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Driving Licence Front & Back</label>
                                <span className="image-container gap-3">
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'driving_licence_front')}
                                    />

                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'driving_licence_back')}
                                    />

                                </span>

                                <span className="image-container justify-content-around mt-2">
                                    {userData?.data?.documents?.driving_licence?.length > 0 ?
                                        (
                                            userData?.data?.documents?.driving_licence.map((document, index) => (
                                                <img className="image-set1" src={baseUrl + '/' + document} key={index} style={{ cursor: 'pointer' }} />
                                            ))

                                        ) : (
                                            <>
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                            </>
                                        )}

                                </span>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Insurance Front & Back</label>
                                <span className="image-container gap-3">
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'insurance_front')}
                                    />
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'insurance_back')}
                                    />
                                </span>

                                <span className="image-container justify-content-around mt-2">
                                    {userData?.data?.documents?.insurance?.length > 0 ?
                                        (
                                            userData?.data?.documents?.insurance.map((document, index) => (
                                                <img className="image-set1" src={baseUrl + '/' + document} key={index} style={{ cursor: 'pointer' }} />
                                            ))
                                        ) : (
                                            <>
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                            </>
                                        )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Vehicle Image Front & Back</label>
                                <span className="image-container gap-3">
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'vehicle_image_front')}
                                    />
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'vehicle_image_back')}
                                    />
                                </span>

                                <span className="image-container justify-content-around mt-2">
                                    {userData?.data?.documents?.vehicle_image?.length > 0 ?
                                        (
                                            userData?.data?.documents?.vehicle_image.map((document, index) => (
                                                <img className="image-set1" src={baseUrl + '/' + document} key={index} style={{ cursor: 'pointer' }} />
                                            ))
                                        ) : (
                                            <>
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                            </>
                                        )}
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='input-filed mt-3'>
                                <label>Vehicle RC Front & Back</label>
                                <span className="image-container gap-3">
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'vehicle_RC_front')}
                                    />
                                    <input
                                        className='py-0 ps-0'
                                        type='file'
                                        onChange={(e) => handleFileChange(e, 'vehicle_RC_back')}
                                    />
                                </span>

                                <span className="image-container justify-content-around mt-2">
                                    {userData?.data?.documents?.vehicle_RC?.length > 0 ?
                                        (
                                            userData?.data?.documents?.vehicle_RC.map((document, index) => (
                                                <img className="image-set1" src={baseUrl + '/' + document} key={index} style={{ cursor: 'pointer' }} />
                                            ))
                                        ) : (
                                            <>
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                                <img className="image-set" src={defaultImageUrl} alt="Default Image" />
                                            </>
                                        )}
                                </span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default EditDriver