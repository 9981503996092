import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
const DashboardCard = (props) => {
    const [isValidate, setIsValidate] = useState(Number(secureLocalStorage.getItem("isValidate")));
    const navigate = useNavigate();
    return (
        <div className='dash-card d-flex align-items-center gap-3 gap-sm-4'>
            <div>
                <span className='dash-icon'>
                    <img src={props.img} alt='' />
                </span>
            </div>
            <h5>{props.title}</h5>
        </div>
    )
}

export default DashboardCard
