import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import SpinnerComp from "./Spinner";
import { GetAllBonus } from "../API/api";
import PaginationComponent from "./Pagination";
const BonusData = ({ search, edit }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totalPage, setTotalPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const startIndex = (currentPage - 1) * perPage + 1;



    const getBonus = async (perPage, page, search) => {
        try {
            const bonus = await GetAllBonus(perPage, page, search);
            console.log("trip", bonus);
            if (bonus.status == 1) {
                setData([...bonus.data]);
                if (bonus.data.length < perPage) {
                    setHasMoreData(false);
                } else {
                    setHasMoreData(true);
                }
                setTotalPage(Math.ceil(bonus.total_count / perPage));
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect((e) => {
        if (search) {
            // console.log("+++++++++++");
            getBonus(perPage, 1, search);
        }
        else {
            getBonus(perPage, currentPage, search);
        }
    }, [search, currentPage, edit]);

    const handlePageChange = (pageNumber) => {
        setLoading(true);
        setCurrentPage(pageNumber);
    };



    return (
        <>
            <Table responsive bordered className="mb-0">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Driver</th>
                        <th>Title</th>
                        <th>Bonus Amount</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {loading ? (
                        <SpinnerComp />
                    ) : (
                        <>
                            {data && data.length > 0 ? (
                                data.map((bonus, index) => (
                                    <tr key={index}>
                                        <td>{startIndex + index}</td>
                                        <td>{bonus?.driver_id?.name || '-'}</td>
                                        <td>{bonus?.title || '-'}</td>
                                        <td>{bonus?.amount || '-'}</td>
                                        <td>{
                                            new Date(bonus.created_at).toLocaleString("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit"
                                            })
                                        }</td>

                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                                </tr>
                            )}
                        </>
                    )}
                </tbody>
            </Table>

            {totalPage > 1 && (
                <PaginationComponent
                    currentPage={currentPage}
                    totalPage={totalPage}
                    handlePageChange={handlePageChange}
                    setPerPage={setPerPage}
                    perPage={perPage}
                />
            )}
        </>
    );
}

export default BonusData 
