// PaginationComponent.js
import React from 'react';
import { Form } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({ currentPage,
    handlePageChange,
    totalPage }) => {
    const renderPaginationItems = () => {
        return (
            <div className="d-flex justify-content-end">
                {totalPage > 0 && (
                    <Pagination className="align-items-center">
                        <Pagination.Prev
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1 ? true : false}
                        >
                            <svg
                                width="10"
                                className="pagination_color"
                                height="14"
                                viewBox="0 0 10 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.50788 -6.11966e-07L0.507813 7L7.50788 14L9.49361 12.0141L4.47941 7L9.49361 1.98587L7.50788 -6.11966e-07Z"
                                    fill={currentPage === 1 ? "#fff" : "#714f38"}
                                />
                            </svg>
                        </Pagination.Prev>

                        {currentPage > 2 && totalPage !== 3 && (
                            <Pagination.Ellipsis onClick={() => handlePageChange(currentPage - 2)} />
                        )}

                        {currentPage == totalPage && totalPage > 2 && (
                            <Pagination.Item
                                active={currentPage === currentPage - 2}
                                onClick={() => handlePageChange(currentPage - 2)}
                            >
                                {currentPage - 2}
                            </Pagination.Item>
                        )}

                        {currentPage != 1 && (
                            <Pagination.Item
                                active={currentPage === currentPage - 1}
                                onClick={() => handlePageChange(currentPage - 1)} currentPage
                            >
                                {currentPage - 1}
                            </Pagination.Item>
                        )}

                        <Pagination.Item
                            active={currentPage === currentPage}
                            onClick={() => handlePageChange(currentPage)}
                        >
                            {currentPage}
                        </Pagination.Item>
                        {currentPage !== totalPage && (
                            <Pagination.Item
                                active={currentPage === currentPage + 1}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                {currentPage + 1}
                            </Pagination.Item>
                        )}
                        {currentPage == 1 && totalPage > currentPage + 1 && (
                            <Pagination.Item
                                active={currentPage === currentPage + 2}
                                onClick={() => handlePageChange(currentPage + 2)}
                            >
                                {currentPage + 2}
                            </Pagination.Item>
                        )}

                        {totalPage > currentPage + 1 && totalPage !== 3 && (
                            <Pagination.Ellipsis onClick={() => handlePageChange(currentPage + 2)} />
                        )}
                        <Pagination.Next
                            onClick={() => handlePageChange(totalPage)}
                            disabled={currentPage === totalPage ? true : false}
                        >
                            <svg
                                width="10"
                                height="14"
                                className="pagination_color"
                                viewBox="0 0 10 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.49212 -6.11966e-07L9.49219 7L2.49212 14L0.506388 12.0141L5.52059 7L0.506388 1.98587L2.49212 -6.11966e-07Z"
                                    fill={currentPage === totalPage ? "#fff" : "#714f38"}
                                />
                            </svg>
                        </Pagination.Next>
                    </Pagination>
                )}

            </div>
        )
    };


    return <Pagination>{renderPaginationItems()}</Pagination>;
};

export default PaginationComponent;
