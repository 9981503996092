import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import { Button, Col, Row, Modal, Pagination, Tooltip, OverlayTrigger } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { getCoupon, deleteCoupon, getCouponsById, disableCoupon, EditCoupon } from "../API/api";
import { toast } from 'react-toastify'
import Spinner from 'react-bootstrap/Spinner';
import TooltipComponent from "../components/TooltipComponent";
import PaginationComponent from "../components/Pagination";

const Coupon = () => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [editCoupon, setEditCoupon] = useState(false);
  const [couponData, setCouponData] = useState({
    title: "",
    code: "",
    exp_date: "",
    amount: "",
    percentage: "",
    minimum_amount: "",
    description: "",
    discount_type: ""
  });
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(6);
  const [totalPage, setTotalPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(true);


  const getAllCoupon = async (perPage, page) => {
    try {
      const user = await getCoupon(perPage, page);
      // console.log("user", user);
      if (user.status == 1) {
        // toast.success(user.message);
        const couponsData = user.data.map((coupon) => ({
          ...coupon,
          disable: coupon.disable,
        }));
        setData(couponsData);
        if (user.data.length < perPage) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
        setTotalPage(Math.ceil(user.total_user / perPage));
        setLoading(false);
      } else {
        toast.error(user.error);
      }
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllCoupon(perPage, currentPage);
  }, [currentPage])

  const handleDeleteModalShow = async (userId) => {
    setDeleteModal(true);
    setId(userId);
  }
  const handleDeleteModalClose = () => {
    setDeleteModal(false);
  }

  const handleEditShow = async (userId) => {
    try {
      const couponInfo = await getCouponsById(userId);
      setCouponData(couponInfo.data);
      setEdit(true);
    } catch (error) {
      toast.error('Error fetching user data', error);
    }
  };
  const handleEditClose = () => {
    setEdit(false);
  }

  const handleEditCouponShow = async (userId) => {
    const couponInfo = await getCouponsById(userId);
    setCouponData(couponInfo.data);
    setEditCoupon(true);
    setId(userId);
  }

  const handleEditCouponClose = () => {
    setEditCoupon(false);
  }
  const handleDelete = async (couponId) => {
    try {
      const deleteCoup = await deleteCoupon(couponId);
      if (deleteCoup.status == 1) {
        toast.success(deleteCoup.message);
        setData((prevData) => prevData.filter((coupon) => coupon._id !== couponId));
        setDeleteModal(false);
        if (data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        } else {
          getAllCoupon(perPage, currentPage);
        }
      } else {
        toast.error(deleteCoup.message);
      }
    } catch (error) {
    }
  }


  const handleAccountChange = async (couponId, status) => {
    try {
      const newCouponData = data.map((coupon) =>
        coupon._id === couponId
          ? {
            ...coupon,
            disable: !coupon.disable,
          }
          : coupon
      );
      setData(newCouponData);
      const disableCop = await disableCoupon(couponId, !status);
      // console.log("newCouponData", disableCop);
      if (disableCop.status === 1) {
        if (disableCop.disable === true) {
          toast.success('Coupon enabled');
        } else {
          toast.success('Coupon disabled');
        }
      } else {
        toast.error(disableCop.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    getAllCoupon(perPage, pageNumber);
  };


  function truncateDescription(description) {
    const maxLength = 35;
    if (description && description.length > maxLength) {
      return `${description.substring(0, maxLength)}...`;
    }
    return description;
  }

  function truncateTitle(title) {
    const maxLength = 15;
    if (title && title.length > maxLength) {
      return `${title.substring(0, maxLength)}...`;
    }
    return title;
  }

  const handleInputChange = (event, fieldName) => {
    const { value } = event.target;

    // For date input, check if it's a valid date and not in the past
    if (fieldName === 'exp_date') {
      const currentDate = new Date().toISOString().split('T')[0];

      if (value < currentDate) {
        toast.error("Expiry date cannot be in the past");
        return;
      }
    }

    setCouponData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: value,
    }));
  };

  const handleSubmitCoupon = async () => {
    try {
      if (!couponData.title || couponData.title.trim() === '') {
        toast.error("Please add title");
        return;
      }
      if (!couponData.code || couponData.code.trim() === '') {
        toast.error("Please add code");
        return;
      }
      if (!couponData.exp_date) {
        toast.error("Please select expiry date");
        return;
      }
      if (!couponData.minimum_amount) {
        toast.error("Please add minimum amount");
        return;
      }
      if (couponData.code.replace(/\s/g, '').length > 12) {
        toast.error("Code must be upto 12 characters");
        return;
      }
      const response = await EditCoupon(id, couponData.title, couponData.code, couponData.exp_date, couponData.amount, couponData.percentage, couponData.minimum_amount, couponData.description, couponData.discount_type);
      if (response.status == 1) {
        setCouponData((prevUserData) => ({
          ...prevUserData,
          ...response.data,
        }));
        toast.success(response.message);
        setEditCoupon(false);
        getAllCoupon(perPage, currentPage);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }


  return (
    <Layout>
      <div className="border-green">
        <div className="py-3 py-sm-4 pb-sm-0 pb-0">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="notify-tab-header d-flex justify-content-end px-3 px-sm-4 pb-4">
                <Button
                  className="fees-btn d-flex align-items-center gap-2"
                  onClick={() => navigate("/add-coupon")}
                >
                  <img src="./img/add.svg" alt="Add" width="12px" />
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 pt-0 pt-sm-0 p-sm-4">
          <Row>
            {loading ? (
              <p className="col-12" style={{ fontSize: 'large', textAlign: 'center', paddingTop: '22px' }}>
                <Spinner animation="border" variant="success" />
              </p>
            ) : (
              <>
                {data && data.length > 0 ? (
                  data.map((d, index) => (
                    <Col lg={4} md={6} sm={12} className="mt-4" key={d._id}>
                      <div className="coupon-card">
                        <div className="coupon-header d-flex align-items-center justify-content-between">
                          <h5>{truncateTitle(d.title)}</h5>
                          <div className="status">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={d.disable}
                                onChange={() => handleAccountChange(d._id, d.disable)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div className="per-list">
                          <h6 className="mt-4 mb-4">{d.code}</h6>
                          {d.amount ? (
                            <p>Amount: {d.amount}</p>
                          ) : (
                            <p>Percentage: {d.percentage}%</p>
                          )}
                          <p>Minimum Price: {d.minimum_amount}</p>
                          <p>Coupon Type: {d.discount_type}</p>
                          <p>Create Date: {
                            new Date(d.created_at).toLocaleString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit"
                            })
                          }</p>
                          <p>Expiry Date: {d.exp_date}</p>
                          <p>Description: {truncateDescription(d.description)}</p>
                        </div>

                        <div className="user-tools coupon-card-btn d-flex align-items-center gap-2 mt-5">
                          <TooltipComponent triggerElement={
                            <Button onClick={() => handleEditShow(d._id)}
                            >
                              <img src="./img/profile/info.svg" alt="Edit" />
                            </Button>
                          }
                            tooltipContent="Info"

                          />
                          <TooltipComponent
                            triggerElement={
                              <Button onClick={() => handleDeleteModalShow(d._id)}>
                                <img src="./img/profile/delete.svg" alt="Delete" />
                              </Button>
                            }
                            tooltipContent="Delete Coupon"
                          />
                          <TooltipComponent
                            triggerElement={
                              <Button onClick={() => handleEditCouponShow(d._id)}>
                                <img src="./img/profile/Edit.svg" alt="Edit" />
                              </Button>
                            }
                            tooltipContent="Edit Coupon"
                          />
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p className="col-12" style={{ fontSize: 'large', textAlign: 'center', fontWeight: '500', paddingTop: '22px' }}>
                    No Data Found
                  </p>
                )}
              </>
            )}
          </Row>
          {/* 
          {totalPage > 1 && <Pagination className="margin">{renderPaginationItems()}</Pagination>} */}
          {totalPage > 1 && (
            <PaginationComponent
              currentPage={currentPage}
              totalPage={totalPage}
              handlePageChange={handlePageChange}
              setPerPage={setPerPage}
              perPage={perPage}
            />
          )}
        </div>

      </div>

      {/* Info Modal */}
      <Modal
        show={edit}
        onHide={handleEditClose}
        centered
        scrollable
        className="edit-user"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center position-relative">
            <h5
              className="modal-title-text modal-border pb-4 text-center"
              style={{ width: "400px" }}
            >
              Coupon Details
            </h5>
            <Button className="close-modal" onClick={handleEditClose}>
              <IoMdClose />
            </Button>
          </div>
          <div className="user-data mt-4">
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Title :</h5>
              <p>{couponData.title}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Code :</h5>
              <p>{couponData.code}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Expiry Date :</h5>
              <p>{couponData.exp_date}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Discount Type :</h5>
              <p>{couponData.discount_type}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              {couponData.percentage ? (
                <>
                  <h5>Percentage :</h5>
                  <p>{couponData.percentage ? couponData.percentage : "-"}</p>
                </>
              ) : (<>
                <h5>Amount  :</h5>
                <p>{couponData.amount ? couponData.amount : "-"}</p>
              </>)}
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Minimum Amount :</h5>
              <p>{couponData.minimum_amount}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Enable :</h5>
              <p>{`${couponData.disable === true ? 'Active' : 'Inactive'}`}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Description :</h5>
              <p>{couponData.description ? couponData.description : "-"}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Modal */}
      <Modal
        show={deleteModal}
        onHide={handleDeleteModalClose}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Delete Coupon
            </h5>
          </div>
          <div className="text-center mt-4">
            <p className="my-2">Are you sure you want to delete this coupon?</p>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleDeleteModalClose} >
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={() => handleDelete(id)}>Delete Coupon</Button>

            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* {Edit Modal} */}

      <Modal
        show={editCoupon}
        onHide={handleEditCouponClose}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Edit Coupon
            </h5>
          </div>
          <form action="" className='mt-5 px-2 px-md-4'>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Title</label>
              <input type="text" className='search-input py-2 border input-heght' placeholder='Title' value={couponData.title} onChange={(e) => handleInputChange(e, 'title')} />
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Code</label>
              <input type="text" className='search-input py-2 border input-heght' placeholder='Code' value={couponData.code} onChange={(e) => handleInputChange(e, 'code')} />
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Expiry Date</label>
              <input type="date" className='search-input py-2 border input-heght date-green' value={couponData.exp_date} onChange={(e) => handleInputChange(e, 'exp_date')}
                min={new Date().toISOString().split('T')[0]} />

            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Amount</label>
              <input type="text" className='search-input py-2 border input-heght ' value={couponData.amount} onChange={(e) => handleInputChange(e, 'amount')} disabled={couponData.percentage} placeholder="Enter Amount" />
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Percentage</label>
              <input type="text" className='search-input py-2 border input-heght ' placeholder='Percentage' value={couponData.percentage ? couponData.percentage : '-'} onChange={(e) => handleInputChange(e, 'percentage')} disabled={couponData.amount} />
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Minimum Amount</label>
              <input type="text" className='search-input py-2 border input-heght ' placeholder='Minimum Amount' value={couponData.minimum_amount
              } onChange={(e) => handleInputChange(e, 'minimum_amount')} />
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Description</label>
              <input type="text" className='search-input py-2 border input-heght ' placeholder='Description' value={couponData.description} onChange={(e) => handleInputChange(e, 'description')} />
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-bold'>Type</label>
              <div className="search-input py-2 border">
                <div className="align-content-center gap-3 justify-content-center">
                  <input
                    className="m-2"
                    type="radio"
                    name="type"
                    id="flat"
                    checked={couponData.discount_type === 'flat'}
                    onChange={() => handleInputChange({ target: { value: 'flat' } }, 'discount_type')}
                  />
                  <label htmlFor="type1">Flat</label>

                  <input
                    className="m-2"
                    type="radio"
                    name="type"
                    id="upto"
                    checked={couponData.discount_type === 'upto'}
                    onChange={() => handleInputChange({ target: { value: 'upto' } }, 'discount_type')}
                  />
                  <label htmlFor="upto">Upto</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center gap-3 mt-4 px-2">
              <Button className="save-btn" onClick={handleEditCouponClose}>
                Cancel
              </Button>
              <Button className="save-btn " style={{ width: "75px" }} onClick={handleSubmitCoupon} >Save</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Coupon;
