import React, { useState, useEffect } from "react";
import Layout from "../layout/Layout";
import UserData from "../components/UserData";

const UserDetail = () => {
  const [searchData, setSearchData] = useState('');

  const handleChange = async (e) => {
    setSearchData(e.target.value.toLowerCase());
  }
  return (
    <Layout>
      <div className="border-green">
        <div className="p-3 p-sm-4">
          <div className="row mb-3">
            <div className="col-lg-4 col-sm-12">
              <input type="text" className="search-input" placeholder="Search Email/Username" name="searchData" value={searchData} onChange={handleChange} />
            </div>
          </div>
          <UserData search={searchData} />
        </div>
      </div>
    </Layout>
  );
};

export default UserDetail;
