import React, { useState } from 'react'
import Layout from "../layout/Layout";
import UserWalletData from "../components/UserWalletData"
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const UserWallet = () => {
    const [searchData, setSearchData] = useState('');

    const handleChange = async (e) => {
        setSearchData(e.target.value.toLowerCase());
    }
    const navigate = useNavigate();
    return (
        <Layout>
            <div className="border-green">
                <div className="p-3 p-sm-4">
                    <div className="row mb-3">
                        <div className="col-lg-12 col-sm-12">
                            <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="Search payment type..."
                                    style={{ maxWidth: "400px" }}
                                    value={searchData} onChange={handleChange}
                                    name="searchData"
                                />
                                <Button className="fees-btn d-flex align-items-center gap-2" onClick={() => navigate("/wallet-details")}>
                                    Back
                                </Button>
                            </div>
                        </div>
                    </div>
                    <UserWalletData search={searchData} />
                </div>
            </div>

        </Layout>
    )
}

export default UserWallet