import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { ForgotPassword } from '../API/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

const ResetPass = () => {
    const [isValidate, setIsValidate] = useState(0);
    const [email, setEmail] = useState('');
    const [buttonText, setButtonText] = useState('Proceed');
    const [message, setMessage] = useState("");
    const [isDisable, setIsDisable] = useState(false);

    const path = window.location.pathname;
    function getPathName() {
        const pathParts = path.split("/");
        const firstPartOfPath = pathParts[1];
        return firstPartOfPath.charAt(0).toUpperCase() + firstPartOfPath.slice(1);
    }
    useEffect(() => {
        document.title = `Towing | ${getPathName()} `;
    }, [path]);

    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        if (message && emailValue) {
            setMessage("");
        }
        setEmail(emailValue);
    };

    const sendEmail = async (e) => {
        try {
            if (email) {
                setButtonText('Sending OTP...');
                setIsDisable(true);
                const user = await ForgotPassword(email);
                setIsDisable(false);
                if (user.status == 1) {
                    toast.success(user.data);
                    setEmail(email);
                    secureLocalStorage.setItem("isValidate", 1);
                    localStorage.setItem("email", email);
                    localStorage.removeItem('otpEndTime');
                    localStorage.removeItem('failedAttempts');
                    navigate("/verification");
                } else {
                    toast.error(user.message);
                    setButtonText(buttonText);
                }
            } else {
                setMessage("Please fill out this field");
            }

        } catch (error) {
            toast.error(error);
        }
    }
    const inputStyle = message ? { borderColor: 'red' } : {};

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            sendEmail();
        }
    };

    return (
        <Fragment>
            <section className='auth-main d-flex align-items-center justify-content-center'>
                <div className='auth-box'>
                    <div>
                        <img src='./img/logo.svg' width="150px" className='logo' />
                        <div className='auth-heading position-relative my-5'>
                            <h1>RESET PASSWORD</h1>
                        </div>
                    </div>

                    <Form className='user-form'>
                        <div className={`input-main-auth`}>
                            <label>Email</label>
                            <input type='email' placeholder='Enter Email' name='email' value={email} onChange={handleEmailChange} style={inputStyle} onKeyPress={(e) => handleKeyPress(e)} />
                            <p className='mt-1 d-flex justify-content-start' style={{ color: 'red', marginTop: '10px' }}>{message && message}</p>

                        </div>
                        <Button type='button' className='submit-btn' onClick={sendEmail} disabled={isDisable}> {buttonText}</Button>
                        <div className='d-flex justify-content-center' >
                            <Link to="/" className='forgot-pass'>Back to sign in</Link>
                        </div>
                    </Form>

                </div>
            </section>
        </Fragment>
    )
}

export default ResetPass
