import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Col, Row } from 'react-bootstrap'
import DashboardCard from '../components/DashboardCard'
import { getTotals, getDriverTotals, getProfile, getAllTrip } from '../API/api'
import { Link, useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const navigate = useNavigate();
    let currentPage = 1;
    let perPage = 10;
    const [data, setData] = useState("");
    const [driverAmount, setDriverAmount] = useState('');
    const [adminAmount, setAdminAmount] = useState('');

    const handleData = async () => {
        try {
            const total = await getTotals();
            setData(total);
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    };

    useEffect(() => {
        handleData();
    }, []);

    const handleData1 = async () => {
        try {
            const total = await getDriverTotals();
            setDriverAmount(total);
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    };

    useEffect(() => {
        handleData1();
    }, []);

    const handleData2 = async () => {
        try {
            const total = await getProfile();
            setAdminAmount(total);
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    };

    useEffect(() => {
        handleData2();
    }, []);

    const GotoTrip = async () => {
        let currentPage = 1;
        if (data.confirmTripCounts) {
            try {
                let tripData;

                tripData = await getAllTrip(perPage, currentPage, null, "done");
                const doneTrip = tripData.data.filter(trip => trip.tow_status === "done");

                navigate('/trip-details', { state: { doneTrip, selectedStatus: "done" } });
            } catch (error) {
                console.error("Error fetching trip data:", error);
            }
        }
    };

    const GoToCancel = async () => {
        if (data.cancelTripCounts) {
            try {
                let tripData;
                tripData = await getAllTrip(perPage, currentPage, null, "canceled");
                const cancelTrip = tripData.data.filter(trip => trip.tow_status === "canceled");
                navigate('/trip-details', { state: { cancelTrip, selectedStatus: "canceled" } });
            } catch (error) {
                console.error("Error fetching trip data:", error);
            }
        }
    }
    /**
     * The function `GoToPending` fetches trip data and filters it to get pending trips, then navigates to
     * the trip details page with the pending trips and selected status.
     */

    const GoToPending = async () => {
        if (data.cancelTripCounts) {
            try {
                let tripData;
                tripData = await getAllTrip(perPage, currentPage, null, "pending");
                const pendingTrip = tripData.data.filter(trip => trip.tow_status === "pending");
                navigate('/trip-details', { state: { pendingTrip, selectedStatus: "pending" } });
            } catch (error) {
                console.error("Error fetching trip data:", error);
            }
        }
    }


    return (
        <Layout>
            <div className='border-green'>
                <div className='p-3 p-sm-4'>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <Link to="/user-details" style={{ textDecoration: 'none' }}>
                                <DashboardCard title={`Total Users : ${data.usersCount ? data.usersCount : 0}`} img="./img/dashboard/user.svg" />
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4 mt-md-0'>
                            <Link to="/driver-details" style={{ textDecoration: 'none' }}>
                                <DashboardCard title={`Total Drivers : ${data.driversCount ? data.driversCount : 0}`} img="./img/dashboard/driver.svg" />
                            </Link>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4 mt-lg-0'>
                            <Link to="/trip-details" style={{ textDecoration: 'none' }}>
                                <DashboardCard title={`Total Trips : ${data.tripCounts ? data.tripCounts : 0}`} img="./img/dashboard/total.svg" />
                            </Link>
                        </Col>
                    </Row>
                </div>
                <h5 className='dashboard-title py-2 px-3 px-sm-4'>Trips</h5>
                <div className='p-3 p-sm-4'>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <a to="#" className='pe-auto' onClick={GotoTrip}>
                                <DashboardCard title={`Confirm Trips : ${data.confirmTripCounts ? data.confirmTripCounts : 0}`} img="./img/dashboard/confirm.svg" />
                            </a>

                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4 mt-md-0'>
                            <a to="#" className='pe-auto' onClick={GoToPending}>
                                <DashboardCard title={`Pending Trips: ${data.pendingTripCounts ? data.pendingTripCounts : 0}`} img="./img/dashboard/pending.svg" />
                            </a>
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4 mt-lg-0'>
                            <a to="#" className='pe-auto' onClick={GoToCancel}>
                                <DashboardCard title={`Cancel Trips : ${data.cancelTripCounts ? data.cancelTripCounts : 0}`} img="./img/dashboard/close.svg" />
                            </a>
                        </Col>
                    </Row>
                </div>
                <h5 className='dashboard-title py-2 px-3 px-sm-4'>Deposits</h5>
                <div className='p-3 p-sm-4'>
                    <Row>
                        <Col lg={4} md={6} sm={12}>
                            <DashboardCard title={`Total Deposits : ${driverAmount.data ? driverAmount.data : 0}`} img="./img/dashboard/amount.svg" />
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4 mt-md-0'>
                            <DashboardCard title={`Our Commissions: ${adminAmount.data?.wallet ? adminAmount.data.wallet : 0}`} img="./img/dashboard/admin.svg" />
                        </Col>
                        <Col lg={4} md={6} sm={12} className='mt-4 mt-lg-0'>
                            <DashboardCard title={`Total Amount :  ${driverAmount.totalAmount
                                ? driverAmount.totalAmount : 0}`} img="./img/dashboard/total-amount.svg" />
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard
