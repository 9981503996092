import React, { useState } from "react";
import Layout from "../layout/Layout";
import { Button, Col, Nav, Row, Tab } from "react-bootstrap";
import { addCoupon } from "../API/api";
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";

const AddCoupon = () => {
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [discount_type, setDiscountType] = useState('flat');
  const [amount, setAmount] = useState('');
  const [percentage, setPercentage] = useState('');
  const [minimum_amount, setMinimumAmount] = useState('');
  const [exp_date, setExpDate] = useState('');
  const [description, setDescription] = useState('');
  const [isDateInvalid, setDateInvalid] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const navigate = useNavigate();

  const handleCheckBox = (value) => {
    setDiscountType(value);
  }

  //Add coupon
  const handleSubmitCoupon = async (e) => {
    try {
      if (!title || title.trim() === '') {
        toast.error("Please add title");
        return;
      }
      if (!code || code.trim() === '') {
        toast.error("Please add code");
        return;
      }
      if (!discount_type) {
        toast.error("Please select discount type");
        return;
      }
      if (!minimum_amount || minimum_amount.trim() === '') {
        toast.error("Please add minimum amount");
        return;
      }
      if (!exp_date) {
        toast.error("Please select expiry date");
        return;
      }
      if (isDateInvalid == true) {
        return;
      }
      if (code.replace(/\s/g, '').length > 12) {
        toast.error("Code must be upto 12 characters");
        return;
      }
      const user = await addCoupon(title, code, amount, percentage, minimum_amount, exp_date, description, discount_type);
      if (user.status == 1) {
        // console.log("user", user);
        toast.success(user.message);
        navigate('/coupon');
      } else {
        toast.error(user.message);
        setCode('');
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleCancelClick = async () => {
    navigate("/coupon");
  }

  const handleCodeChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\s/g, ''); // Remove spaces
    if (sanitizedValue.length <= 12) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    setCode(sanitizedValue);
  }

  const handleDateChange = (e) => {
    const enteredDate = new Date(e.target.value);
    const currentDate = new Date();

    if (enteredDate <= currentDate) {
      setDateInvalid(true);
    } else {
      setDateInvalid(false);
    }
    setExpDate(e.target.value);
  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitCoupon();
    }
  };

  return (
    <Layout>
      <div className="border-green">
        <div className="py-3 py-sm-4">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="notify-tab-header d-flex align-content-center justify-content-between gap-3 flex-wrap position-relative">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first" >Add Coupon</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="p-3">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Title</label>
                            <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter Title" />
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Code</label>
                            <input type="text" name="code" value={code} onChange={handleCodeChange} placeholder="Enter Code" />
                            {isValid && (
                              <div className='validation-message'>
                                Enter code upto 12 character only
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Amount</label>
                            <input type="text" name="amount" value={amount} onChange={(e) => {
                              const inputValue = e.target.value;
                              if (parseFloat(inputValue) > 0 || inputValue === "") {
                                setAmount(inputValue);
                              }
                            }} disabled={percentage} placeholder="Enter Amount" />
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Percentage</label>
                            <input type="text" name="percentage" value={percentage} onChange={(e) => {
                              const inputValue = e.target.value;
                              if (parseFloat(inputValue) > 0 || inputValue === "") {
                                setPercentage(inputValue);
                              }
                            }} disabled={amount} placeholder="Enter Percentage" />
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Minimum Amount</label>
                            <input type="text" name="minimum_amount" value={minimum_amount} onChange={(e) => {
                              const inputValue = e.target.value;
                              if (parseFloat(inputValue) > 0 || inputValue === "") {
                                setMinimumAmount(inputValue);
                              }
                            }} placeholder="Enter Minimum Amount" />
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Expiry Date</label>
                            <input
                              className="date-green"
                              type="date"
                              name="exp_date"
                              value={exp_date}
                              onChange={handleDateChange}
                              min={new Date().toISOString().split('T')[0]}
                            />
                            {isDateInvalid && (
                              <div className='validation-message'>
                                Invalid Date! Please select a valid or future date
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <div className="fees-input mt-3">
                            <label>Description</label>
                            <input type="text" name="description" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter Description" />
                          </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <label className="type-title mt-3">Type</label>
                          <div className="select-type flex-wrap">
                            <div className="d-flex align-content-center gap-3 justify-content-center">
                              <input
                                className="custom-radio"
                                type="radio"
                                name="type"
                                id="flat"
                                checked={discount_type == 'flat' || discount_type == ''}
                                onChange={() => handleCheckBox('flat')}
                                onKeyPress={(e) => handleKeyPress(e)}
                              />
                              <label htmlFor="type1">Flat</label>
                            </div>
                            <div className="d-flex align-content-center gap-3 justify-content-center">
                              <input
                                type="radio"
                                name="type"
                                id="upto"
                                checked={discount_type == 'upto'}
                                onChange={() => handleCheckBox('upto')}
                                onKeyPress={(e) => handleKeyPress(e)}
                              />
                              <label htmlFor="type2">Upto</label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end gap-3">
                        <Button className="save-btn" onClick={handleSubmitCoupon}>Save</Button>
                        <Button className="save-btn cancel-btn" onClick={handleCancelClick}>Cancel</Button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddCoupon;
