import React, { createContext, useEffect, useState } from "react";
import { GetCountry, disableDriver } from "../API/api";
import { toast } from "react-toastify";
export const TowContext = createContext();

export const TowProvider = ({ children }) => {
    const [isValidate, setIsValidate] = useState(0);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [getCountry, setGetCountry] = useState([]);

    const setLocalCountry = (country) => {
        setSelectedCountry(country);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            disableDriver();
        }, 2 * 10000); // 10 seconds in milliseconds

        return () => clearInterval(intervalId);
    }, []);

    const getCountryData = async (perPage = 10, page = 1) => {
        try {
            const getData = await GetCountry(perPage, page);
            if (getData.status == 1) {
                setGetCountry(getData.data);
            } else {
                toast.error(getData.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }
    return (
        <TowContext.Provider value={{ isValidate, setIsValidate, selectedCountry, setLocalCountry, disableDriver, getCountry, getCountryData }}>
            {children}
        </TowContext.Provider>
    );
}
