export const email_re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const pwd_re =
  /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;

export const ph_re = /^\d{10}$/;

export const validateEmail = (email) => {
  const is_email_valid = String(email).match(email_re);
  return is_email_valid;
};

export const validatePhoneNumber = (phone_no) => {
  return ph_re.test(phone_no);
};

export const validatePassword = (password) => {
  return pwd_re.test(password);
};
