import React, { useState, useEffect, useRef, useContext } from "react";
import { Button, Modal, Table, } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { GetAllDrivers, deleteDriverById, getDriversById, towStatus, disableAccountAdmin, documentVerification, towStatusShow, documentStatusShow, GetActiveTow } from "../API/api";
import { toast } from 'react-toastify'
import SpinnerComp from "./Spinner";
import TooltipComponent from "./TooltipComponent";
import PaginationComponent from "./Pagination";
import { useNavigate } from "react-router-dom";
import { TowContext } from "../context/context";

const DriverData = ({ search, add }) => {
  const { getCountry, getCountryData } = useContext(TowContext);
  const [edit, setEdit] = useState(false);
  const [driverData, setDriverData] = useState({});
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModal1, setDeleteModal1] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [selectTow, setSelectTow] = useState('');
  const [documentVerify, setDocumentVerify] = useState('approved');
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const defaultImageUrl = "./img/document.jpg";
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [towData, setTowData] = useState([]);
  const [docData, setDocData] = useState('');
  const [country, setCountry] = useState('');
  const [selectCount, setSelectCount] = useState(0);
  const [tow, setTow] = useState({});
  const startIndex = (currentPage - 1) * perPage + 1;
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  const onCountryChange = (selectedCountry) => {
    setSelectCount(selectCount + 1);
    if (selectedCountry) {
      setCountry(selectedCountry);
    } else {
      setCountry(driverData.country)
    }
  };

  // useEffect(() => {
  // }, [country]);


  useEffect(() => {
    if (getCountry.length > 0) {
      setCountry(driverData.country);
    }
  }, [getCountry]);

  const handleEditClose = () => setEdit(false);

  const handleEditShow = async (userId) => {
    try {
      const driverInfo = await getDriversById(userId);
      setDriverData(driverInfo.data);
      setEdit(true);
      setId(userId);
      fetchDataTow(userId);
      fetchDataDocument(userId)
    } catch (error) {
      toast.error('Error fetching driver data', error);
    }
  }

  const handleDeleteModalClose = async () => {
    setDeleteModal(false);
  }

  const handleVerifyModalClose = async () => {
    setVerifyModal(false);
  }

  const handleDeleteModalShow = async (userId) => {
    setDeleteModal(true);
    const towData = await towStatusShow(driverData._id);
    setId(userId);
  }

  const handleDeleteModalClose1 = async () => {
    setDeleteModal1(false);
  }

  const handleDeleteModalShow1 = async (userId) => {
    setDeleteModal1(true);
    setId(userId);
  }
  const handleVerificationModelShow = (userId) => {
    if (driverData.document_verification !== 'Not Upload') {
      setVerifyModal(true);
      setId(userId);
    }

  }
  const handleCheckboxChange = (value) => {
    setSelectTow(value);
  };
  const handleCheckboxVerify = (value) => {
    setDocumentVerify(value);
  };

  const handleTowStatus = async () => {
    try {
      const updateTow = await towStatus(driverData._id, selectTow, country);
      if (updateTow.status == 1) {
        toast.success(updateTow.message);
        setDeleteModal(false);
      } else {
        toast.error(updateTow.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // useEffect(() => {
  const fetchDataTow = async (id) => {
    try {
      const response = await towStatusShow(id);
      if (response.status === 1 && response.data.length > 0) {
        setTowData(response.data);
        setSelectTow(response.data);
      }
    } catch (error) {
      console.error("Error fetching tow data", error);
    }
  };
  //   fetchData();
  // }, [driverData._id]);

  // useEffect(() => {
  const fetchDataDocument = async (id) => {
    try {
      const response = await documentStatusShow(id);
      setDocData(response.data);
      setDocumentVerify(response.data);

    } catch (error) {
      console.error("Error fetching tow data", error);
    }
  };
  //   fetchData();
  // }, [driverData._id]);



  const handleDocumentVerify = async () => {
    try {
      const docVerify = await documentVerification(driverData._id, documentVerify);
      if (docVerify.status = 1) {
        toast.success(docVerify.message);
        setVerifyModal(false);
        setEdit(false);
        getDrivers(perPage, currentPage, search);
      } else {
        toast.error(docVerify.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleAccountChange = async (accountId, status) => {
    try {

      const disableUser = await disableAccountAdmin(accountId, !status);
      if (disableUser.status === 1) {

        const newUsersData = data.map((user) =>
          user._id === accountId
            ? {
              ...user,
              admin_flag: !user.admin_flag,
              // disable_account: !user.disable_account,
            }
            : user
        );

        setData(newUsersData);

        if (disableUser.disable_account == true || disableUser.admin_flag == true) {
          toast.success("Driver Blocked");
        } else {
          toast.success('Driver Unblocked');
        }
      } else {
        toast.error(disableUser.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getDrivers = async (perPage, page, search) => {
    try {
      const user = await GetAllDrivers(perPage, page, search, 'driver');
      if (user.status === 1) {
        const usersData = user.data.map((user) => ({
          ...user,
          disable_account: user.disable_account,
          admin_flag: user.admin_flag
        }));
        setData(usersData);
        if (user.data.length < perPage) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
        setTotalPage(Math.ceil(user.total_user / perPage));
      }
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching users:", error);
      setLoading(false);
    }
  }


  useEffect(() => {
    // if (search) {
    //   getDrivers(perPage, 1, search);
    // } else {
    getDrivers(perPage, currentPage, search);
    // }
  }, [search, add]);



  // useEffect(() => {
  //   getCountryData();
  // }, [])


  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    getDrivers(perPage, pageNumber, search);
  };

  const handleDelete = async (id) => {
    try {
      const deleteUser = await deleteDriverById(id);
      if (deleteUser.status == 1) {
        toast.success(deleteUser.message);
        setData((prevData) => prevData.filter((user) => user._id !== id));
        setDeleteModal1(false);
        if (data.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        } else {
          getDrivers(perPage, currentPage, search);
        }
      } else {
        toast.error(deleteUser.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const getTowData = async () => {
    try {
      const countryToFetch = driverData.country || getCountry[0].country_name;
      const getData = await GetActiveTow(selectCount > 0 ? country : countryToFetch);

      if (getData.status == 1) {
        setTow(getData.data);
      }
    } catch (error) {
      // console.log('not found');
    }
  }

  useEffect(() => {
    getTowData();
  }, [selectCount, driverData.country, country]);

  const defaultImageSrc = "./img/user.jpg";
  const profileImageSrc = driverData.profile_image ? baseUrl + '/' + driverData.profile_image : defaultImageSrc;

  const handleClickEdit = async (userId) => {
    navigate('/edit-driver', { state: { selectedId: userId } });
    setId(userId)
  }
  // console.log("id", id);

  function truncateTitle(name) {
    const maxLength = 10;
    if (name && name.length > maxLength) {
      return `${name.substring(0, maxLength)}...`;
    }
    return name;
  }


  return (
    <>
      <Table responsive bordered className="mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Deposit</th>
            <th>Phone</th>
            <th>Documents</th>
            <th>Created at</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <SpinnerComp />
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((driver, index) => {
                  return (<tr key={index}>
                    <td>{startIndex + index}</td>
                    <td>{truncateTitle(driver.name)}</td>
                    <td>{driver.email}</td>
                    <td>{driver.deposit_amount ? driver.deposit_amount : "-"}</td>
                    <td>{driver.phone_no}</td>
                    <td>{driver.document_verification}</td>
                    <td>{
                      new Date(driver.created_at).toLocaleString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit"
                      })
                    }</td>
                    <td>
                      <div className="status">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={(!driver.disable_account && !driver.admin_flag) ? true : false}
                            onChange={() => handleAccountChange(driver._id, driver.admin_flag)}
                          />
                          {/* {index === 3 && console.log(driver.email, driver.disable_account, !driver.admin_flag, !driver.disable_account && !driver.admin_flag, driver)} */}
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="user-tools d-flex align-items-center gap-2 justify-content-center">
                        <TooltipComponent
                          triggerElement={
                            <Button onClick={() => handleEditShow(driver._id)}>
                              <img src="./img/profile/info.svg" alt="" />
                            </Button>
                          }
                          tooltipContent="Info" />
                        <TooltipComponent
                          triggerElement={
                            <Button onClick={() => handleDeleteModalShow1(driver._id)}>
                              <img src="./img/profile/delete.svg" alt="" />
                            </Button>
                          }
                          tooltipContent="Delete Driver" />
                        <TooltipComponent
                          triggerElement={
                            <Button onClick={() => handleClickEdit(driver._id)}>
                              <img src="./img/profile/Edit.svg" alt="" />
                            </Button>
                          }
                          tooltipContent="Edit Driver" />
                      </div>
                    </td>
                  </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                </tr>
              )}
            </>
          )}

        </tbody>
      </Table>
      {totalPage > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPage={totalPage}
          handlePageChange={handlePageChange}
          setPerPage={setPerPage}
          perPage={perPage}
        />
      )}

      <Modal
        show={edit}
        onHide={handleEditClose}
        centered
        scrollable
        className="edit-user"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center position-relative">
            <h5
              className="modal-title-text modal-border pb-4 text-center"
              style={{ width: "400px" }}
            >
              Driver Details
            </h5>
            <Button className="close-modal" onClick={handleEditClose}>
              <IoMdClose />
            </Button>
          </div>

          <div className="user-data driver-data mt-4">
            <div className="d-flex align-content-center justify-content-center gap-4">
              <div className="profile_image">
                <img src={profileImageSrc} width="60px" height="60px" style={{ borderRadius: "50%" }} />
              </div>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Name :</h5>
              <p>{driverData.name}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>ID :</h5>
              <p>{driverData._id}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Role :</h5>
              <p>{driverData.user_role}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Email :</h5>
              <p>{driverData.email}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Phone No. :</h5>
              <p>{driverData.phone_no ? driverData.phone_no : '-'}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Document Verification :</h5>
              <p>{driverData.document_verification}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5 >Vehicle Type :</h5>
              <p className="transform">
                {driverData.ride_type ? driverData.ride_type : '-'}
              </p>

            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Registered Through :</h5>
              <p className="transform">{driverData.social_media}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Company Type :</h5>

              <p>{driverData.company ? driverData.company : '-'}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Status :</h5>
              <p className="transform">{driverData.status}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Registration At :</h5>
              <p>{
                new Date(driverData.created_at).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit"
                })
              }</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Last updated :</h5>
              <p>{
                new Date(driverData.updated_at).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit"
                })
              }
              </p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5 className="d-flex justify-content-center gap-4 mt-2">Documents :</h5>
            </div>
          </div>
          <div className="document-main d-flex align-content-center gap-3 flex-wrap mt-4">
            <div className="doc-box">
              <div className="doc-img">
                {driverData?.documents?.vehicle_RC?.length > 0 ? (
                  driverData.documents?.vehicle_RC.map((document, index) => (
                    <img src={baseUrl + '/' + document} key={index} onClick={() => setSelectedDocument(baseUrl + '/' + document)} style={{ cursor: 'pointer' }} />
                  ))
                ) : (
                  <>
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                  </>
                )}
              </div>
              <p>Vehicle RC</p>
            </div>

            <div className="doc-box">
              <div className="doc-img">
                {driverData?.documents?.driving_licence?.length > 0 ? (
                  driverData.documents?.driving_licence.map((document, index) => (
                    <img src={baseUrl + '/' + document} key={index} onClick={() => setSelectedDocument(baseUrl + '/' + document)} style={{ cursor: 'pointer' }} />
                  ))
                ) : (
                  <>
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                  </>
                )}
              </div>
              <p>Driving Licence</p>
            </div>

            <div className="doc-box">
              <div className="doc-img">
                {driverData?.documents?.insurance?.length > 0 ? (
                  driverData.documents?.insurance.map((document, index) => (
                    <img src={baseUrl + '/' + document} key={index} onClick={() => setSelectedDocument(baseUrl + '/' + document)} style={{ cursor: 'pointer' }} />
                  ))
                ) : (
                  <>
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                  </>
                )}
              </div>
              <p>Insurance</p>
            </div>

            <div className="doc-box">
              <div className="doc-img">
                {driverData?.documents?.vehicle_image?.length > 0 ? (
                  driverData.documents?.vehicle_image.map((document, index) => (
                    <img src={baseUrl + '/' + document} key={index} onClick={() => setSelectedDocument(baseUrl + '/' + document)} style={{ cursor: 'pointer' }} />
                  ))
                ) : (
                  <>
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                    <img src={defaultImageUrl} alt="Default Image" onClick={() => setSelectedDocument(defaultImageUrl)} style={{ cursor: 'pointer' }} />
                  </>
                )}
              </div>
              <p>Vehicle Image</p>
            </div>

            <div className="d-flex delete-user align-items-center gap-2 mt-4 px-2 w-100">
              <Button className="delete-tools w-100" onClick={handleDeleteModalShow}>
                Trip Class
              </Button>
              <Button
                className={`delete-tools ${driverData.document_verification === 'Not Upload' ? 'disabled' : 'active'} w-100`}
                onClick={driverData.document_verification !== 'Not Upload' ? handleVerificationModelShow : null}
                disabled={driverData.document_verification === 'Not Upload'}
              >
                {driverData.document_verification}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModal1}
        onHide={handleDeleteModalClose1}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Delete Driver
            </h5>
          </div>
          <div className="text-center mt-4">
            <p className="my-2">Are you sure you want to delete this driver?</p>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleDeleteModalClose1}>
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={() => handleDelete(id)}>Delete Driver</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={handleDeleteModalClose}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Select Trip Type
            </h5>
          </div>
          <div className="d-flex justify-content-center position-relative flex-wrap mt-2">

            <select className="search-option search-option-pdding" value={selectCount > 0 ? country : driverData.country} onChange={(e) => onCountryChange(e.target.value)}>
              {getCountry.map((c, index) => {
                return <option>{c.country_name}</option>
              })}
            </select>
          </div>
          <div className="text-center mt-4">
            <p className="my-2">Which Class Does This Trip Belong?</p>
            <div className="my-3 select-trip">
              {tow.class?.map((t, index) => (
                <div key={index} className="d-flex align-content-center gap-3 justify-content-center m-2">
                  <input
                    type="radio"
                    className="cos-radio-btn"
                    name="trip"
                    id={t.type}
                    onChange={() => handleCheckboxChange(t.type)}
                    checked={selectTow === t.type}
                  />
                  {console.log("selectTow", selectTow, t.type)}
                  <label htmlFor="trip1" className="transform">{t.type} Tow</label>
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleDeleteModalClose}>
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={() => handleTowStatus(id)}>Save</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={verifyModal}
        onHide={handleVerifyModalClose}
        centered
        scrollable
        className="edit-user delete-user"
      >
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Verify Document
            </h5>
          </div>
          <div className="text-center mt-4">
            <div className="my-3 select-trip">
              <div className="d-flex align-content-center gap-3 justify-content-center">
                <input
                  type="radio"
                  className="cos-radio-btn"
                  name="verify"
                  id="rejected"
                  onChange={() => handleCheckboxVerify('rejected')}
                  checked={documentVerify === 'rejected'}
                />
                <label htmlFor="verify1">Rejected</label>
              </div>
              <div className="d-flex align-content-center gap-3 justify-content-center mt-2">
                <input
                  type="radio"
                  className="cos-radio-btn"
                  name="verify"
                  id="approved"
                  onChange={() => handleCheckboxVerify('approved')}
                  checked={documentVerify === 'approved'}
                />
                <label htmlFor="verify2">Approved</label>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleVerifyModalClose}>
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={() => handleDocumentVerify(id)}>Save</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={selectedDocument !== null} centered scrollable onHide={() => setSelectedDocument(null)}>
        <Modal.Body>
          {selectedDocument && <img src={selectedDocument ? selectedDocument : defaultImageUrl} alt="Selected Document" style={{ width: '100%' }} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DriverData;
