import React, { useEffect, useState, useRef } from "react";
import Layout from "../layout/Layout";
import { Button, Col, Pagination, Row } from "react-bootstrap";
import io from 'socket.io-client';
import { GetAll, GetMessage } from "../API/api";
import Spinner from 'react-bootstrap/Spinner';

const Contact = () => {
  const [message, setMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const adminId = localStorage.getItem('id')
  const [lastMessages, setLastMessages] = useState({});
  const chatAreaRef = useRef();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_PUBLIC_URL);
    setSocket(newSocket);
    newSocket.on('message_from_admin', (data) => {
      // console.log("Received message_from_admin data:", data);
      setMessages((prevMessages) => [...prevMessages, { role: 'admin', message: data.message, userId: selectedUser?._id, receiverUserId: 'admin', createdAt: data.createdAt }]);
      // console.log(messages, "message_from_admin============");
    });

    newSocket.on('message_from_user', (data) => {
      const createdAt = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      });
      if (selectedUser && data.data.sender_user === selectedUser?._id) {
        setMessages((prevMessages) => [...prevMessages, { role: 'user', message: data.data.message, userId: 'admin', receiverUserId: data.data.sender_user, createdAt: createdAt }]);
      }
      // console.log(messages, "message_from_user============");
      // console.log(data.data.sender_user, selectedUser?._id);
      getAllUsers(perPage, currentPage)
    });

    // Register as admin
    newSocket.emit('register_admin');

    return () => newSocket.close();
  }, [selectedUser, setSocket]);


  const sendMessage = () => {
    if (socket && message) {
      const currentDate = new Date().toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true
      });
      setMessages(prevMessages => [
        ...prevMessages,
        { role: 'admin', message: message, userId: 'admin', receiverUserId: selectedUser._id, createdAt: currentDate }
      ]);

      // Emit the message to the server
      socket.emit('send_message', {
        role: 'admin',
        message: message,
        userId: 'admin',
        receiverUserId: selectedUser?._id,
        createdAt: currentDate
      });
      setMessage('');
      getAllUsers(perPage, currentPage)
    }
  };

  //all user details 
  const getAllUsers = async (perPage, page, searchData) => {
    try {
      const getData = await GetAll(perPage, page, searchData);

      if (getData.status == 1) {
        setData(getData.data);
        if (getData.data.length < perPage) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
        setTotalPage(Math.ceil(getData.total_user / perPage));
        setLoading(false);
      } else {
        console.error("Error fetching users:");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (searchData) {
      getAllUsers(perPage, 1, searchData)
    } else {
      getAllUsers(perPage, currentPage)
    }
  }, [searchData]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await GetAll(perPage, 1);
      if (result.data && result.data.length > 0) {
        handleUserSelect(result.data[0]);
      }
    };
    fetchData();
  }, []);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSelectedUserId(user._id);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      if (selectedUser) {
        try {
          // console.log("helo--------", selectedUser);
          const response = await GetMessage(selectedUser._id, 'admin');

          const userMessages = response.data;
          const messages = userMessages.map(message => ({
            role: message.sender_user === 'admin' ? 'admin' : 'user',
            message: message.message,
            createdAt: new Date(message.created_at).toLocaleString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true
            }),
          }));
          setMessages(messages);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      }
    };

    fetchMessages();
  }, [selectedUser, adminId]);

  useEffect(() => {
    if (messages.length > 0) {
      const lastUserMessage = messages.find(msg => msg.role === 'user' || msg.role === 'admin');
      setLastMessages(prevState => ({
        ...prevState,
        [selectedUser?._id]: lastUserMessage
      }));
    }
  }, [messages]);

  const handleChange = async (e) => {
    setSearchData(e.target.value.toLowerCase());
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllUsers(perPage, pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    if (totalPage <= 1) return null;

    items.push(
      <Pagination.Prev
        key="prev"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />
    );

    for (let i = 1; i <= totalPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    items.push(
      <Pagination.Next
        key="next"
        disabled={currentPage === totalPage}
        onClick={() => handlePageChange(currentPage + 1)}
      />
    );

    return items;
  };
  useEffect(() => {
    const chatArea = chatAreaRef.current;
    if (chatArea) {
      chatArea.scrollTop = chatArea.scrollHeight;
    }
  }, [messages, selectedUser]);

  function truncateTitle() {
    const maxLength = 40;
    if (selectedUser.name && selectedUser.name.length > maxLength) {
      return `${selectedUser.name.substring(0, maxLength)}...`;
    }
    return selectedUser.name;
  }

  return (
    <Layout>
      <Row>
        <Col xl={4} lg={4} md={6} sm={12}>
          <div className="border-green p-3 p-sm-4">
            <input type="text" className="tow-class" placeholder="Search..." name="searchData" value={searchData} onChange={handleChange} />
            <div className="users-list mt-4">
              {loading ? (
                <p style={{ fontSize: '16px', textAlign: 'center', fontWeight: '500' }}> <Spinner animation="border" variant="success" /></p>
              ) : (
                <>
                  {data && data.length > 0 ? (
                    data.map((contact, index) => {
                      // const isSelected = contact._id === selectedUserId;
                      return (
                        // <div className={`user-chat-box d-flex align-items-center gap-3 mb-3 ${isSelected ? 'selected-user' : ''}`} key={index}
                        <div className="user-chat-box d-flex align-items-center gap-3 mb-3" key={index}

                          onClick={() => handleUserSelect(contact)}
                        >
                          <img src={contact.profile_image ? baseUrl + '/' + contact.profile_image : "./img/chat/user.png"} alt="" />

                          <div style={{ width: "80%" }}>
                            <div className="d-flex align-content-center justify-content-between gap-3">
                              <h5>{contact.name}</h5>
                              <span>{
                                new Date(contact.lastMessageTime).toLocaleString("en-US", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit"
                                })
                              }</span>
                            </div>
                            <p>{contact.lastMessage}</p>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <p style={{ fontSize: '16px', textAlign: 'center', fontWeight: '500' }}>No matching user found. Try again!</p>
                  )}
                </>
              )}
            </div>
            {totalPage > 1 && (
              <Pagination className="custom-pagination">{renderPaginationItems()}</Pagination>
            )}
          </div>
        </Col>
        <Col xl={8} lg={8} md={6} sm={12} className="mt-4 mt-md-0">
          {selectedUser ? (<div className="border-green">
            <div className=" p-3 p-sm-3 notify-tab-header">
              <div className="user-chat-box d-flex align-items-center gap-3">
                <img src={selectedUser.profile_image ? baseUrl + '/' + selectedUser.profile_image : "./img/chat/user.png"} alt="" />
                <div>
                  <h5>{truncateTitle(selectedUser?.name)}</h5>
                  <p>Last Seen at {
                    new Date(selectedUser.lastMessageTime).toLocaleString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit"
                    })
                  }</p>
                </div>
              </div>
            </div>

            <div className="p-3 p-sm-3 chat-area" ref={chatAreaRef} style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <ul>
                {messages.map((msg, index) => {
                  if (msg.role === 'user') {
                    return (
                      <li className="receiver-msg" key={index}>
                        <div className="msg d-flex align-items-start gap-3">

                          <img src={selectedUser.profile_image ? baseUrl + '/' + selectedUser.profile_image : "./img/chat/user.png"} alt="" />
                          <div>
                            <p>{msg.message}</p>
                          </div>
                        </div>
                        <span className="msg-time" style={{ marginLeft: "52px" }}>
                          {msg.createdAt}
                        </span>
                      </li>
                    );
                  } else if (msg.role === 'admin') {
                    return (
                      <li className="send-msg ms-auto mt-3" key={index}>
                        <div className="msg d-flex align-items-start gap-3" style={{ justifyContent: 'end' }}>
                          <div className="send-msg-text">
                            <p>{msg.message}</p>
                          </div>
                        </div>
                        <span className="msg-time text-end w-100 d-inline-block">
                          {msg.createdAt}
                        </span>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>

            <div className="p-3">
              <div className="type-msg">
                <input type="text" placeholder="Type here..." value={message}
                  onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />
                <Button onClick={sendMessage}>
                  <img src="./img/chat/send.png" alt="" />
                </Button>
              </div>
            </div>
          </div>) : (
            <>
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default Contact;
