import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, NavLink } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { newPassword } from '../API/api'
import secureLocalStorage from 'react-secure-storage'
import { checkMissingParams } from '../Helper/helper'
import { validatePassword } from '../Helper/validation'
const NewPassword = () => {

    const [showPass, setShowPass] = useState();
    const [showCPass, setShowCPass] = useState();
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isValidate, setIsValidate] = useState(Number(secureLocalStorage.getItem("isValidate")));
    const [matchPassword, setMatchPassword] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem("email"));
    const navigate = useNavigate();

    const path = window.location.pathname;
    function getPathName() {
        const pathParts = path.split("/");
        const firstPartOfPath = pathParts[1];
        return firstPartOfPath.charAt(0).toUpperCase() + firstPartOfPath.slice(1);
    }
    useEffect(() => {
        document.title = `Towing | ${getPathName()} `;
    }, [path]);


    const validateForm = async () => {
        let message, isValid;
        if (!isPasswordValid) {
            isValid = 0;
            message = "Password is not secure enough";
        }
        if (!matchPassword) {
            message = `Please ensure passwords match`;
        }

        const required_params = {
            "new password": password,
            "confirm password": cPassword,
        };
        const missing_params = await checkMissingParams(required_params);
        if (missing_params.status == 0) {
            isValid = 0;
            message = missing_params.message;
        }

        if (message) {
            return { is_valid: false, message, type: "error" };
        }

        return { isValid: true };
    };

    const matchPasswords = () => {
        if (
            password &&
            cPassword &&
            password === cPassword
        ) {
            setMatchPassword(true);
        } else {
            setMatchPassword(false);
        }
    };

    useEffect(() => {
        matchPasswords();
    }, [cPassword, password]);

    const handleSubmit = async (e) => {
        const validate = await validateForm();
        if (validate.isValid) {
            const user = await newPassword(password, email);
            if (user.status = 1) {
                toast.success(user.message);
                secureLocalStorage.removeItem("isValidate");
                localStorage.removeItem("email");
                navigate('/');
            }
            else {
                toast.error(user.message)
            }
        } else {
            toast.error(validate.message);
        }
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    useEffect(() => {
        if (isValidate != 1) {
            navigate('/');
        }
    }, [isValidate]);

    return (
        <Fragment>
            <section className='auth-main d-flex align-items-center justify-content-center'>
                <div className='auth-box'>
                    <div>
                        <img src='./img/logo.svg' width="150px" className='logo' />
                        <div className='auth-heading position-relative my-5'>
                            <h1>NEW PASSWORD</h1>
                        </div>
                    </div>

                    <Form className='user-form'>
                        <div className='input-main-auth'>
                            <label>New Password</label>
                            <div className='position-relative'>
                                <input type={showPass ? ' text' : 'password'} placeholder='Enter Password' value={password} onChange={(e) => {
                                    const pwd_val = e.target.value;
                                    setPassword(pwd_val);
                                    setIsPasswordValid(validatePassword(pwd_val));
                                }}
                                />
                                <Button className='show-pass' onClick={() => setShowPass(!showPass)}>
                                    {
                                        showPass ?
                                            <img src='./img/show-pass.svg' width="25px" />
                                            :
                                            <img src='./img/hide-pass.svg' width="25px" />
                                    }
                                </Button>
                            </div>
                            {password && !isPasswordValid && (
                                <p className="text-danger text-start inner-alerts">
                                    Password requirements:
                                    <br />
                                    Minimum 8 characters, cannot contain spaces
                                    <br />
                                    <b>Must include</b>: special characters, numbers, lower case and
                                    upper case letters
                                </p>
                            )}
                        </div>
                        <div className='input-main-auth mt-4'>
                            <label>Confirm Password</label>
                            <div className='position-relative'>
                                <input
                                    type={showCPass ? 'text' : 'password'}
                                    placeholder='Enter Confirm Password'
                                    value={cPassword}
                                    onChange={(e) => setCPassword(e.target.value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                />

                                <Button className='show-pass' onClick={() => setShowCPass(!showCPass)}>
                                    {
                                        showCPass ?
                                            <img src='./img/show-pass.svg' width="25px" />
                                            :
                                            <img src='./img/hide-pass.svg' width="25px" />
                                    }
                                </Button>
                            </div>
                            {password &&
                                cPassword &&
                                (matchPassword ? (

                                    <p className="text-success text-start inner-alerts mt-2">
                                        ✅ Passwords Matched
                                    </p>

                                ) : (
                                    <p className="text-danger text-start inner-alerts mt-2">
                                        ❌ Password does not match
                                    </p>
                                ))}
                        </div>
                        <Button className='submit-btn' onClick={handleSubmit}>SUBMIT</Button>
                    </Form>


                </div>
            </section>
        </Fragment>
    )
}

export default NewPassword
