import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { getAllTrip, GetTripById } from "../API/api";
import { toast } from 'react-toastify'
import SpinnerComp from "./Spinner";
import { useLocation } from 'react-router-dom';
import PaginationComponent from "./Pagination";
import TooltipComponent from "./TooltipComponent";
import { IoMdClose } from "react-icons/io";

const TripData = ({ search, selectedStatus }) => {
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const startIndex = (currentPage - 1) * perPage + 1;
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const tripData = location?.state?.doneTrip;
  const cancelTrip = location?.state?.cancelTrip;
  const pendingTrip = location?.state?.pendingTrip;

  // console.log("trip=============", tripData);

  const handleEditClose = () => setEdit(false);

  const handleEditShow = async (userId) => {
    try {
      const userInfo = await GetTripById(userId);
      setUserData(userInfo.data);
      setEdit(true);
    } catch (error) {
      toast.error('Error fetching user data', error);
    }
  };

  useEffect(() => {
    if (tripData || cancelTrip || pendingTrip) {
      const selectedTripData = tripData || cancelTrip || pendingTrip;
      setData(selectedTripData);
      const totalPages = Math.ceil(selectedTripData?.length / perPage);
      setTotalPage(totalPages);
      setHasMoreData(selectedTripData?.length >= perPage);
      setLoading(false);
    }
  }, [tripData, cancelTrip, pendingTrip, perPage]);



  const getTrips = async (perPage, page, search) => {
    try {
      const trip = await getAllTrip(perPage, page, search, selectedStatus);
      // console.log("trip", trip);
      if (trip.status === 1) {
        setData([...trip.data]);
        if (trip.data.length < perPage) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
        setTotalPage(Math.ceil(trip.total_count / perPage));
      }
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching users:", error);
      setLoading(false);
    }
  }


  // const handlePageChange = (pageNumber) => {
  //   setLoading(true);
  //   setCurrentPage(pageNumber);
  //   if (search) {
  //     console.log("helllooooooo");
  //     getTrips(perPage, pageNumber, search);
  //   } else if (selectedStatus) {
  //     getTrips(perPage, pageNumber, selectedStatus);
  //   }
  //   else {
  //     getTrips(perPage, pageNumber);
  //   }
  // };

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);

    // Define parameters for getTrips function
    let searchParam = search || selectedStatus ? search : undefined;

    getTrips(perPage, pageNumber, searchParam, selectedStatus);
  };

  useEffect(() => {
    if (search || selectedStatus) {
      getTrips(perPage, 1, search, selectedStatus);
    }
    else {
      getTrips(perPage, currentPage);
    }
  }, [search, selectedStatus]);


  const generateStarRating = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={i <= rating ? 'star-filled' : 'star-empty'}>
          &#9733;
        </span>
      );
    }
    return stars;
  };

  function truncateDescription(userFeedback) {
    const maxLength = 15;
    if (userFeedback && userFeedback.length > maxLength) {
      return `${userFeedback.substring(0, maxLength)}...`;
    }
    return userFeedback;
  }


  return (
    <>
      <Table responsive bordered className="mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th>User</th>
            <th>Driver</th>
            <th>Tow Type</th>
            <th>From</th>
            <th>To</th>
            <th>Trip Status</th>
            <th>Commission Amount</th>
            <th>Payment Amount</th>
            <th>User Feedback</th>
            <th>User Review</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <SpinnerComp />
          ) : (
            <>
              {data && data.length > 0 ? (
                data.map((trip, index) => (
                  <tr key={index}>
                    {/* {console.log("data", data)} */}
                    <td>{startIndex + index}</td>
                    <td>{trip?.user_id?.name || '-'}</td>
                    <td>{trip?.driver_id?.name || '-'}</td>
                    <td className="transform">{trip?.tows || '-'}</td>
                    <td>{trip?.from?.location.split(',').slice(-4, -1).join(',').trim() || '-'}</td>
                    <td>{trip?.to?.location.split(',').slice(-4, -1).join(',').trim() || '-'}</td>
                    <td className="transform">{trip?.tow_status || '-'}</td>
                    <td>{trip?.admin_commission ? trip?.admin_commission + ' ' + trip?.driver_id?.currency : '-'}</td>
                    <td>{trip?.payment || '-'} {trip?.driver_id?.currency}</td>
                    <td>{truncateDescription(trip?.userFeedback || '-')}</td>
                    <td>{generateStarRating(trip?.userRating)}</td>
                    <td>
                      <div className="user-tools d-flex align-items-center gap-2 justify-content-center">
                        <TooltipComponent
                          triggerElement={
                            <Button onClick={() => handleEditShow(trip._id)}>

                              <img src="./img/profile/info.svg" alt="" />
                            </Button>
                          }
                          tooltipContent="Info"
                        />
                        {/* <TooltipComponent
                          triggerElement={
                            <Button >
                              <img src="./img/profile/delete.svg" alt="" />
                            </Button>
                          }
                          tooltipContent="Delete User"
                        /> */}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>

      {totalPage > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPage={totalPage}
          handlePageChange={handlePageChange}
        />
      )}

      <Modal
        show={edit}
        onHide={handleEditClose}
        centered
        scrollable
        className="edit-user"
      >
        <Modal.Body>
          <div className="d-flex justify-content-center position-relative">
            <h5
              className="modal-title-text modal-border pb-4 text-center"
              style={{ width: "400px" }}
            >
              Trip Details
            </h5>
            <Button className="close-modal" onClick={handleEditClose}>
              <IoMdClose />
            </Button>
          </div>
          <div className="user-data mt-4">

            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>User Name :</h5>
              <p>{userData.user_id?.name}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Driver Name :</h5>
              <p>{userData.driver_id?.name}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Tow Type :</h5>
              <p className="transform">{userData.tows}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>From Location :</h5>
              <p>{userData.from?.location}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>To Location. :</h5>
              <p>{userData.to?.location}</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Trip Status :</h5>
              <p className="transform">{userData.tow_status}</p>
            </div>

            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Payment Amount :</h5>
              <p className="font" >{
                userData.payment
              }</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Payment Method :</h5>
              <p className="font" >{
                userData.payment_method
              }</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>User Feedback :</h5>
              <p>{
                userData.userFeedback ? userData.userFeedback : "-"
              }</p>
            </div>
            <div className="d-flex align-content-center justify-content-center gap-4 mt-3">
              <h5>Created At :</h5>
              <p>{
                new Date(userData.updated_at).toLocaleString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TripData;
