import React, { useState } from 'react'
import Layout from "../layout/Layout";
import WalletData from '../components/WalletData';

const WalletDetail = () => {
    const [searchData, setSearchData] = useState('');

    const handleChange = async (e) => {
        setSearchData(e.target.value.toLowerCase());
    }
    return (
        <Layout>
            <div className="border-green">
                <div className="p-3 p-sm-4">
                    <div className="row mb-3">
                        <div className="col-lg-12 col-sm-12">
                            <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="Search driver..."
                                    style={{ maxWidth: "400px" }}
                                    value={searchData} onChange={handleChange}
                                    name="searchData"
                                />
                            </div>
                        </div>
                    </div>
                    <WalletData search={searchData} />
                </div>
            </div>

        </Layout>
    )
}

export default WalletDetail