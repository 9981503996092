import React, { useState, useEffect } from "react";
import { Pagination, Table } from "react-bootstrap";
import { getFeesDetails } from "../API/api";
import SpinnerComp from "./Spinner";
import PaginationComponent from "./Pagination";

const FeesData = ({ country }) => {
  const [data, setData] = useState([]);
  const [fixFeesData, setFixFeesData] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const getFees = async (perPage, page) => {
    try {
      const fee = await getFeesDetails(perPage, page, country);

      if (fee.status == 1) {
        setData([...fee.data.other_class]);
        setFixFeesData([...fee.fixFees.other_class]);
        console.log("fee.data", fee.data.other_class.length, perPage, fee);
        if (fee.data.other_class.length < perPage) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
        }
        setTotalPage(Math.ceil(fee.total_fee / perPage));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  // console.log("fee", fixFeesData);

  useEffect(() => {
    setLoading(true);
    getFees(perPage, currentPage, country);
  }, [currentPage, country])

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    getFees(perPage, pageNumber);
  };
  const uniqueTypes = new Set([...data.map(item => item.type), ...fixFeesData.map(item => item.type)]);

  return (
    <>
      <Table responsive bordered className="mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th>Type</th>
            <th>Dynamic Price</th>
            <th>Fix Price</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <SpinnerComp />
          ) : (
            <>
              {uniqueTypes.size > 0 ? (
                Array.from(uniqueTypes).map((type, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{type}</td>
                    <td>{data.find(item => item.type === type)?.price}</td>
                    <td>{fixFeesData.find(item => item.type === type)?.price}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" style={{ fontSize: 'large' }}>
                    No Data Found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      {totalPage > 1 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPage={totalPage}
          handlePageChange={handlePageChange}
          setPerPage={setPerPage}
          perPage={perPage}
        />
      )}
    </>
  );
};

export default FeesData;
