import React, { useContext, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { GetTow, CreateTow, DisableTow, UpdateTow, DeleteTow } from "../API/api";
import { toast } from "react-toastify";
import TooltipComponent from "../components/TooltipComponent";
import { TowContext } from "../context/context";

const Class = () => {
  const [tow, setTow] = useState({});
  const [title, setTitle] = useState('');
  const [typeNew, setTypeNew] = useState('');
  const [updateImage, setUpdateImage] = useState({});
  const [image, setImage] = useState({});
  const [country, setCountry] = useState('');
  const { getCountry, getCountryData } = useContext(TowContext);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isError1, setIsError1] = useState(false);

  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  const baseUrl = process.env.REACT_APP_PUBLIC_URL;

  const onCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    getTowData(selectedCountry);
  };

  useEffect(() => {
    if (getCountry.length > 0) {
      setCountry(getCountry[0].country_name);
    }
  }, [getCountry]);

  const handleEditClose = () => {
    setEdit(false);
    setTitle('');
  }


  const handleDeleteClose = () => {
    setDeleteModal(false);
  }

  const handleDeleteShow = async (rideClass) => {
    const selectedClass = tow.class.find((t) => t.type === rideClass);
    setTitle(selectedClass.type)
    setDeleteModal(true);
  }

  const handleEditShow = async (rideClass) => {
    const selectedClass = tow.class.find((t) => t.type === rideClass);
    setTitle(selectedClass.type)
    setEdit(true);
    setTypeNew(selectedClass.type);
    if (selectedClass.image) {
      const imagePathArray = selectedClass.image.split('/');
      const filename = imagePathArray[imagePathArray.length - 1];
      setUpdateImage(filename);
    } else {
      setUpdateImage("");
    }
  };

  const handleUpdateClass = async () => {
    try {
      if (!typeNew) {
        toast.error('Please add tow class');
        return;
      }
      if (isError1 == true) {
        toast.error("Invalid file format")
        return;
      }
      const response = await UpdateTow(title, country, image, typeNew);
      if (response.status === 1) {
        toast.success('Tow class updated successfully.');
        setTitle('');
        setImage('');
        setUpdateImage('');
        setTypeNew('');
        handleEditClose();
        getTowData(country);
      } else {
        toast.error('Failed to update tow class image.');
      }
    } catch (error) {
      toast.error('Failed to update tow class image.');
    }
  };

  const handleDeleteClass = async () => {
    try {
      const deleteClass = await DeleteTow(title, country);
      if (deleteClass.status === 1) {
        toast.success(deleteClass.message);
        setTitle('');
        handleDeleteClose();
        getTowData(country);
      } else {
        toast.error(deleteClass.message);
      }
    } catch (error) {
      toast.error(error)
    }
  }

  const getTowData = async (selectedCountry) => {
    try {
      setLoading(true);
      const getData = await GetTow(selectedCountry);
      if (getData.status === 1) {
        setTow(getData.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTowData(country);
  }, [country]);

  useEffect(() => {
    getCountryData();
  }, []);

  // Add class function using add class api
  const handleAddClass = async () => {
    try {
      if (!title || title.trim() === '') {
        toast.error('Please add title');
        return;
      }
      if (isError1 == true) {
        toast.error("Invalid file format")
        return;
      }
      const addClass = await CreateTow(title, country, image);
      if (addClass.status === 1) {
        setTitle('');
        setImage('');
        toast.success(addClass.message);
        getTowData(country);
      } else {
        toast.error(addClass.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  // Change check  box value on click for active class
  const handleCheckboxChange = async (status, rideClass) => {
    try {
      const newUsersData = tow.class.map((ts) =>
        ts.type === rideClass
          ? {
            ...ts,
            is_active: !ts.is_active,
          }
          : ts
      );
      setTow({ ...tow, class: newUsersData });
      const response = await DisableTow(country, rideClass, !status);
      if (response.status === 1) {
        if (response.is_active === true) {
          toast.success("Tow class enabled");
        } else {
          toast.success('Tow class disabled');
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  // Change file function
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && allowedFileTypes.includes(file.type)) {
      setImage(file);
      setIsError1(false);
    } else {
      setImage(file);
      setIsError1(true);
    }
  };

  return (
    <Layout>
      <div className="border-green">
        <div className="p-3 p-sm-4">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <input
                type="text"
                className="tow-class"
                placeholder="Enter Driver Class"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mt-3 mt-md-0">
              <input type="file" id="choose-file" className="choose-file" onChange={handleFileChange} />
              <label htmlFor="choose-file" className="file-input truncate">
                {image?.name || "Add Image"}
              </label>
            </div>

            <div className="col-lg-6 col-md-12 mt-3 mt-lg-0">
              <div className="d-flex align-content-center justify-content-end gap-3">
                <select className="search-option" onChange={(e) => onCountryChange(e.target.value)} value={country}>
                  {getCountry.map((c, index) => {
                    return <option key={index} value={c.country_name}>{c.country_name}</option>
                  })}
                </select>
                <Button className="fees-btn green-btn d-flex align-items-center  gap-2" onClick={handleAddClass}>
                  <img src="./img/add.svg" alt="Add" width="12px" />
                  Add
                </Button>
              </div>
            </div>
          </div>

          <div className="tow-option mt-4">
            <div className="tow-option mt-4">
              <Row>
                {loading ? (
                  <p className="col-12" style={{ fontSize: 'large', textAlign: 'center', paddingTop: '22px' }}>
                    <Spinner animation="border" variant="success" />
                  </p>
                ) : (
                  <>
                    {tow?.class?.length > 0 ? (
                      <Col lg={4} md={6}>
                        {tow.class.map((t, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-center tow-text gap-3 " style={{ width: "80%" }}>
                              <div>
                                {t.image ? (
                                  <img src={baseUrl + '/' + t.image} alt="Uploaded Image" style={{ width: '97px', height: '53px' }} />
                                ) : (
                                  <img src="./img/tow/tow1.svg" alt="" style={{ width: '97px', height: '53px' }} />
                                )}
                              </div>
                              <h5 className="transform">{t.type}</h5>
                            </div>

                            <div>
                              <div className="user-tools d-flex align-items-center gap-2 justify-content-center" style={{ marginLeft: '10px' }}>
                                <div className="status">
                                  <label className="switch">
                                    <input type="checkbox" checked={t.is_active} onChange={() => handleCheckboxChange(t.is_active, t.type)} />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                                <TooltipComponent
                                  triggerElement={
                                    <Button onClick={() => handleEditShow(t.type)}>
                                      <img src="./img/profile/Edit.svg" alt="" />
                                    </Button>
                                  }
                                  tooltipContent="Edit"
                                />
                                <TooltipComponent
                                  triggerElement={
                                    <Button onClick={() => handleDeleteShow(t.type)}>
                                      <img src="./img/profile/delete.svg" alt="" />
                                    </Button>
                                  }
                                  tooltipContent="Delete Class"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </Col>
                    ) : (
                      <p className="col-12" style={{ fontSize: 'large', textAlign: 'center', fontWeight: '500', paddingTop: '22px' }}>
                        No Data Found
                      </p>
                    )}
                  </>
                )}
              </Row>
            </div>

          </div>
        </div>
      </div>

      {/* Edit class modal */}
      <Modal
        show={edit}
        onHide={handleEditClose}
        centered
        scrollable
        className="edit-user">
        <Modal.Body>
          <div>
            <input
              type="text"
              className="tow-class"
              placeholder="Update Tow Class"
              name="typeNew"
              value={typeNew}
              onChange={(e) => setTypeNew(e.target.value)}
            />
          </div>
          <div >
            <input type="file" id="choose-file-modal" className="choose-file" onChange={handleFileChange} />
            <label htmlFor="choose-file" className="file-input mt-2 truncate" >
              {image?.name || updateImage || "Update Image"}
            </label>
          </div>
          <div className="d-flex align-content-center justify-content-end mt-3">
            <Button className="fees-btn green-btn d-flex align-items-center  gap-2" onClick={handleUpdateClass}>
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete class modal */}
      <Modal show={deleteModal}
        onHide={handleDeleteClose} centered className="edit-user delete-user">
        <Modal.Body className="py-4">
          <div className="d-flex justify-content-center position-relative">
            <h5 className="modal-title-text modal-border pb-3 text-center w-100">
              Delete Class
            </h5>
          </div>
          <div className="text-center mt-4">
            <p className="my-2">Are you sure you want to delete {title} class?</p>
            <div className="d-flex align-items-center gap-2 mt-4 px-2">
              <Button className="delete-tools" onClick={handleDeleteClose}>
                Cancel
              </Button>
              <Button className="delete-tools active" onClick={handleDeleteClass}>Delete Class</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Class;
