import React, { useContext, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import { addMinFees, addFixFees, addDynamicFees, addAdminFees, getFixFees, getDynamicFees } from "../API/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TowContext } from "../context/context";
const AddFees = () => {
  const [editFixed, setEditFixed] = useState(false);
  const [editSurge, setEditSurge] = useState(false);
  const [editMinimum, setEditMinimum] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [otherMinimumRide, setOtherMinimumRide] = useState([]);
  const [otherRide, setOtherRide] = useState([]);
  const [currency, setCurrency] = useState("");
  const [handlingFee, setHandlingFee] = useState("");
  const [processingFee, setProcessingFee] = useState("");
  const [meter, setMeter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDayIndex, setStartDayIndex] = useState("");
  const [endDayIndex, setEndDayIndex] = useState("");
  const [increment, setIncrement] = useState("");
  const [decrement, setDecrement] = useState("");
  const [adminPercentage, setAdminPercentage] = useState("");
  const [adminCharge, setAdminCharge] = useState("");

  const [otherRideFinal, setOtherRideFinal] = useState([]);
  const location = useLocation();
  const towContext = useContext(TowContext);
  const { selectedCountry, setLocalCountry } = towContext;
  const initialCountry = location.state?.country || selectedCountry;

  const [country, setCountry] = useState(initialCountry);
  const [isDateInvalid, setDateInvalid] = useState(false);
  const [isDateInvalid1, setDateInvalid1] = useState(false);

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  //Get fix fees
  const getFixData = async () => {
    try {
      const getData = await getFixFees(country);
      if (getData.status === 1) {
        setOtherMinimumRide(getData.data.other_class_minimum);
        setOtherRide(getData.data.other_class);
        setCurrency(getData.data.currency);
        setHandlingFee(getData.data.handling_fee);
        setProcessingFee(getData.data.processing_fee);
        setMeter(getData.data.meter);
        setAdminPercentage(getData.data.admin_fee_percent);
        setAdminCharge(getData.data.admin_min_charge);
        setCountry(getData.data.country);
      }
    } catch (error) {
      console.log('not found');
    }
  }
  useEffect(() => {
    getFixData();
  }, [country]);

  //Get Dynamic fees  
  const getDynamicFee = async () => {
    const dynamicData = await getDynamicFees(country);
    if (dynamicData.status === 1) {
      setStartDate(dynamicData.data.start_date);
      setStartTime(dynamicData.data.start_time);
      setStartDayIndex(dynamicData.data.start_day);
      setEndDayIndex(dynamicData.data.end_day);
      setEndTime(dynamicData.data.end_time);
      setEndDate(dynamicData.data.end_date);
      setIncrement(dynamicData.data.increment);
      setDecrement(dynamicData.data.decrement);
      setOtherRideFinal(dynamicData.data.other_class);
      setCountry(dynamicData.data.country);
    }
  }
  useEffect(() => {
    getDynamicFee()
  }, [country])


  useEffect(() => {
    // console.log("Country updated:", country);
  }, [country]);


  const changeValue = (e, i) => {
    const inputValue = e.target.value;
    if (parseFloat(inputValue) > 0 || inputValue === "") {
      setOtherMinimumRide(otherMinimumRide?.map((v, index) => {
        if (i === index) {
          v.price = inputValue;
          return v;
        }
        return v;
      }))
    }

  }

  const changeClassValue = (e, i) => {
    const inputValue = e.target.value;
    if (parseFloat(inputValue) > 0 || inputValue === "") {
      setOtherRide(otherRide?.map((v, index) => {
        if (i === index) {
          v.price = inputValue;
          return v;
        }
        return v;
      }))
    }

  }

  //Add minimum fees
  const handleSubmit = async (e) => {
    try {
      const setMinFee = await addMinFees(country, otherMinimumRide);
      if (setMinFee.status === 1) {
        toast.success(setMinFee.message);
        setEditMinimum(false);

      } else {
        toast.error(setMinFee.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  //Add fix fees  
  const handleSubmitFix = async () => {
    try {
      const setFixFee = await addFixFees(country, currency, processingFee, handlingFee, meter, otherRide);
      if (setFixFee.status === 1) {
        toast.success(setFixFee.message);
        setEditFixed(false);
        await addDynamicFees(startDate,
          endDate,
          startTime,
          endTime,
          startDayIndex,
          endDayIndex,
          decrement,
          increment,
          country);
        getDynamicFee();
      } else {
        toast.error(setFixFee.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  //Add dynamic fees
  const handleSubmitDynamic = async () => {
    try {
      if (isDateInvalid === true) {
        return;
      }
      if (isDateInvalid1 === true) {
        return;
      }
      const setDynamicFee = await addDynamicFees(startDate,
        endDate,
        startTime,
        endTime,
        startDayIndex,
        endDayIndex,
        decrement,
        increment,
        country);
      if (setDynamicFee.status === 1) {
        toast.success(setDynamicFee.message);
        setEditSurge(false);
        getDynamicFee();
        // navigate('/fees-details');
      } else {
        toast.error(setDynamicFee.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }


  //Add admin fees
  const handleAdminSubmit = async () => {
    try {
      const setAdminFee = await addAdminFees(country, adminPercentage, adminCharge);
      if (setAdminFee.status === 1) {
        toast.success(setAdminFee.message);
        setEditAdmin(false);
        navigate('/fees-details');
      } else {
        toast.error(setAdminFee.message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleDateChange = (e) => {
    const enteredDate = new Date(e.target.value);
    const currentDate = new Date();

    if (enteredDate.toDateString() < currentDate.toDateString()) {
      setDateInvalid(true);
    } else if (enteredDate.toDateString() === currentDate.toDateString()) {
      setDateInvalid(false);
    } else {
      setDateInvalid(false);
    }
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date time to start of the day

    if (selectedDate < currentDate) {
      setDateInvalid1(true);
    } else {
      setDateInvalid1(false);
    }

    setEndDate(e.target.value);
  };
  const navigate = useNavigate();


  return (
    <Layout>
      <div className="border-green">
        <div className="p-3 p-sm-4 pb-sm-0 pb-0">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                <Button className="fees-btn d-flex align-items-center gap-2 green-btn"
                  onClick={() => navigate("/fees-details", { state: { selectedCountry } })}>
                  Back
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="px-3 px-sm-4 pb-5 border-green-bottom">
          <Row className="align-items-end">
            {otherMinimumRide && otherMinimumRide.map((v, i) => (
              <Col key={i} lg={3} md={6} sm={12}>
                <div className="fees-input mt-3">
                  <label style={{
                    textTransform: "capitalize",
                    color: editMinimum && "#32325d",
                  }}>{v.type}</label>
                  <input
                    type="number"
                    placeholder={`Enter min ${v.type} fee`}
                    value={v.price}
                    onChange={(e) => {
                      changeValue(e, i);
                    }}
                    readOnly={!editMinimum}
                    className={editMinimum ? "dark-font" : ""}
                  />
                </div>
              </Col>
            ))}
            <Col lg={12} md={12} sm={12}>
              <div className="fees-input mt-3 d-flex justify-content-end">
                {editMinimum ? (
                  <Button className="fixed-fee" onClick={handleSubmit}>
                    Update
                  </Button>
                ) : (
                  <Button
                    className="fixed-fee"
                    onClick={() => {
                      setEditMinimum(true);
                    }}
                  >
                    Set Minimum Fee
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className="px-3 px-sm-4 py-4 py-sm-5 border-green-bottom">
          <Row className="align-items-end">
            {otherRide && otherRide.map((v, i) => (
              <Col key={i} lg={3} md={6} sm={12}>
                <div className="fees-input mt-3">
                  <label style={{
                    textTransform: "capitalize",
                    color: editFixed && "#32325d",
                  }}>{v.type}</label>
                  <input
                    type="number"
                    placeholder={`Enter fix ${v.type} fee`}
                    value={v.price || ""}
                    onChange={(e) => {
                      changeClassValue(e, i)
                    }}
                    readOnly={!editFixed}
                    className={editFixed ? "dark-font" : ""}
                  />
                </div>
              </Col>
            ))}
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Processing Fee</label>
                <input type="number" placeholder="Enter processing fee" name="processingFee" value={processingFee} onChange={(e) => {
                  const inputValue = e.target.value;
                  if (parseFloat(inputValue) > 0 || inputValue === "") {
                    setProcessingFee(inputValue);
                  }
                }} readOnly={!editFixed} className={editFixed ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Currency</label>
                <input type="text" placeholder="Enter currency" name="currency" value={currency} onChange={(e) => {
                  setCurrency(e.target.value);
                }} readOnly={!editFixed} className={editFixed ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Meter</label>
                <input type="number" placeholder="Enter meter" name="meter" value={meter} onChange={(e) => {
                  setMeter(e.target.value);
                }} readOnly={!editFixed} className={editFixed ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Handling Fee</label>
                <input type="number" placeholder="Enter handling fee" name="handlingFee" value={handlingFee} onChange={(e) => {
                  const inputValue = e.target.value;
                  if (parseFloat(inputValue) > 0 || inputValue === "") {
                    setHandlingFee(inputValue);
                  }
                }} readOnly={!editFixed} className={editFixed ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={12} md={12} sm={12}>
              <div className="fees-input mt-3 d-flex justify-content-end">
                {editFixed ? (
                  <Button className="fixed-fee" onClick={handleSubmitFix}>
                    Update
                  </Button>
                ) : (
                  <Button
                    className="fixed-fee"
                    onClick={() => {
                      setEditFixed(true);
                    }}
                  >
                    Set Fix Fee
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>

        <div className="px-3 px-sm-4 py-4 py-sm-5 border-green-bottom">
          <Row className="align-items-end">
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Start Date</label>
                <input type="date" placeholder="Enter start date" name="start_date" value={startDate} onChange={handleDateChange} readOnly={!editSurge} className={editSurge ? "dark-font" : ""} min={new Date().toISOString().split('T')[0]} />
                {isDateInvalid && (
                  <div className='validation-message'>
                    Invalid Date! Please select a valid or future date
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Start Time</label>
                <input type="time" placeholder="Enter start time" name="start_time" value={startTime} onChange={(e) => {
                  setStartTime(e.target.value);
                }} readOnly={!editSurge} className={editSurge ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Start Day</label>
                <Dropdown className="button-light">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "12rem", }}
                    disabled={!editSurge}
                    className={editSurge ? "dark-font" : ""}
                  >
                    {weekdays[startDayIndex]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ margin: "0" }}>
                    {weekdays.map((day, i) => (
                      <Dropdown.Item
                        onClick={() => {
                          setStartDayIndex(i);
                        }}
                        key={i}
                      >
                        {day}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Increment</label>
                <input type="number" placeholder="Enter increment" name="increment" value={increment} onChange={(e) => {
                  const inputValue = e.target.value;
                  if (parseFloat(inputValue) > 0 || inputValue === "") {
                    setIncrement(inputValue);
                  }
                }} disabled={decrement} readOnly={!editSurge} className={editSurge ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>End Date</label>
                <input type="date" placeholder="Enter end date" name="end_date" value={endDate} onChange={handleEndDateChange} readOnly={!editSurge} className={editSurge ? "dark-font" : ""}
                  min={new Date().toISOString().split('T')[0]}
                />
                {isDateInvalid1 && (
                  <div className='validation-message'>
                    Invalid Date! Please select a valid or future date
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>End Time</label>
                <input type="time" placeholder="Enter end time" name="end_time" value={endTime} onChange={(e) => {
                  setEndTime(e.target.value);
                }} readOnly={!editSurge} className={editSurge ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>End Day</label>
                <Dropdown className="button-light">
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className={editSurge ? "dark-font" : ""}
                    style={{ width: "12rem", }}
                    disabled={!editSurge}
                  >
                    {weekdays[endDayIndex]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ margin: "0" }}>
                    {weekdays.map((day, i) => (
                      <Dropdown.Item
                        key={i}
                        onClick={() => {
                          setEndDayIndex(i);
                        }}
                      >
                        {day}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Decrement</label>
                <input type="number" placeholder="Enter decrement" name="decrement" value={decrement} onChange={(e) => {
                  const inputValue = e.target.value;
                  if (parseFloat(inputValue) > 0 || inputValue === "") {
                    setDecrement(inputValue);
                  }
                }} disabled={increment} readOnly={!editSurge} className={editSurge ? "dark-font" : ""} />
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-between flex-wrap mt-3 gap-3">
            <div className="d-flex align-items-center gap-2 gap-sm-5 flex-wrap tows-text">
              {
                otherRideFinal && otherRideFinal.map((v, i) => {
                  return (
                    <span className="mx-4">
                      {v.type}:{" "}
                      <span className="font-weight-bold">
                        {v.price ? v.price : "0"}
                      </span>
                    </span>
                  )
                })
              }
            </div>
            {editSurge ? (
              <Button className="fixed-fee" onClick={handleSubmitDynamic}>
                Update
              </Button>
            ) : (
              <Button
                className="fixed-fee"
                onClick={() => {
                  setEditSurge(true);
                }}
              >
                Set Dynamic Fee
              </Button>
            )}
          </div>
        </div>

        <div className="px-3 px-sm-4 pt-4 pb-5">
          <Row className="align-items-end">
            <Col lg={3} md={6} sm={12}>
              <div className="fees-input mt-3">
                <label>Admin Fee percent</label>
                <input type="number" placeholder="Enter admin percentage" value={adminPercentage} name="adminPercentage" onChange={(e) => {
                  const inputValue = e.target.value;
                  if (parseFloat(inputValue) > 0 || inputValue === "") {
                    setAdminPercentage(inputValue);
                  }
                }} readOnly={!editAdmin} className={editAdmin ? "dark-font" : ""} />
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="fees-input mt-3">
                {editAdmin ? (
                  <Button className="fixed-fee" onClick={handleAdminSubmit}>
                    Update
                  </Button>
                ) : (
                  <Button
                    className="fixed-fee"
                    onClick={() => {
                      setEditAdmin(true);
                    }}
                  >
                    Set Admin Fee
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default AddFees;
