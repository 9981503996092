export const checkMissingParams = async (params) => {
    let missing_params = [];
    let message = "";
    Object.entries(params).map(([key, value]) => {
        if (!value) {
            missing_params.push(key);
        }
    });
    if (missing_params.length) {
        let field_name = missing_params[0];
        field_name = field_name.replaceAll("_", " ");
        message = `${field_name[0].toUpperCase() + field_name.substring(1)
            } is required!`;
        return { status: 0, missing_params, message };
    }
    return { status: 1, message: "" };
};
