import React, { useEffect, useState } from 'react'
import Layout from "../layout/Layout";
import BonusData from '../components/BonusData';
import { Button, Modal } from "react-bootstrap";
import { GetDrivers, AddBonus } from '../API/api';
import { toast } from 'react-toastify'

function BonusDetail() {
    const [searchData, setSearchData] = useState('');

    const [edit, setEdit] = useState(false);
    const [data, setData] = useState([]);
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const handleChange = async (e) => {
        setSearchData(e.target.value.toLowerCase());
    }

    const handleEditClose = () => {
        setEdit(false);
    }
    const handleEditShow = () => {
        setEdit(true);
    }

    const getDriver = async () => {
        const getData = await GetDrivers();
        if (getData.status == 1) {
            setData(getData.data);
        }
    }

    useEffect(() => {
        getDriver()
    }, [])


    //Add Submit bonus 
    const handleSubmitBonus = async () => {
        try {
            if (!email) {
                toast.error("Please select a driver ");
                return;
            }
            if (!title) {
                toast.error("Please fill title");
                return;
            }
            if (!amount) {
                toast.error("Please add some amount");
                return;
            }
            const setBonus = await AddBonus(email, title, amount);
            if (setBonus.status == 1) {
                setTitle('');
                setAmount('');
                setEmail('');
                toast.success(setBonus.message);
                setEdit(false);
                BonusData.getBonus(perPage, currentPage, searchData);

            } else {
                toast.error(setBonus.message);
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const handleClose = async () => {
        setEdit(false);
    }

    const handleAmount = async (e) => {
        const inputValue = e.target.value;
        const regex = /^\d+(\.\d{0,2})?$/;
        if (inputValue === "" || regex.test(inputValue)) {
            setAmount(inputValue);
        }
    }
    return (
        <Layout>
            <div className="border-green">
                <div className="p-3 p-sm-4">
                    <div className="row mb-3">
                        <div className="col-lg-12 col-sm-12">
                            <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="Search driver..."
                                    style={{ maxWidth: "400px" }}
                                    value={searchData} onChange={handleChange}
                                    name="searchData"
                                />
                                <Button className="fees-btn d-flex align-items-center gap-2" onClick={handleEditShow}>
                                    <img src="./img/add.svg" alt="Add" width="12px" />
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                    <BonusData search={searchData} edit={edit} />
                </div>
            </div>

            <Modal
                show={edit}
                onHide={handleEditClose}
                centered
                scrollable
                className="edit-user">
                <Modal.Body>
                    <div className="col-10 col-md-8 m-auto">
                        <h4 className='border-bottom border-dark text-center pb-3 fw-bold'>Add Bonus</h4>
                    </div>
                    <form action="" className='mt-5 px-2 px-md-4'>
                        <label className='mb-2 fw-bold'>Driver</label>
                        <select className="form-select input-heght mb-3" aria-label="Default select example" value={email} onChange={(e) => setEmail(e.target.value)}>
                            <option>Driver</option>
                            {data && data.map((driver) => (
                                <option key={driver.email} value={driver.email}>
                                    {driver.email}
                                </option>
                            ))}
                        </select>
                        <div className='mb-3'>
                            <label className='fw-bold mb-2'>Title</label>
                            <input type="text" className='search-input py-2 border input-heght' placeholder='Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className='mb-3'>
                            <label className='fw-bold mb-2'>Amount</label>
                            <input type="text" className='search-input border input-heght' placeholder='Amount' value={amount} onChange={handleAmount} />
                        </div>

                        <div className="d-flex align-items-center gap-3 mt-4 px-2">
                            <Button className="save-btn" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button className="save-btn " style={{ width: "75px" }} onClick={handleSubmitBonus}>Save</Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default BonusDetail
