import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Modal, Pagination, Table } from 'react-bootstrap'
import { AddCountryData, GetCountry, DeleteCountry, getCountries } from '../API/api'
import { toast } from 'react-toastify'
import SpinnerComp from '../components/Spinner'
import TooltipComponent from '../components/TooltipComponent';

const AddCountry = () => {
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("");
    const selectCountryHandler = (value) => setSelectedCountry(value);
    const [deleteModal, setDeleteModal] = useState(false);
    const [data, setData] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPage, setTotalPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const startIndex = (currentPage - 1) * perPage + 1;
    const [country, setCountry] = useState([]);

    const getCountriesData = async () => {
        const data = await getCountries();
        setCountry(data.data);
    }

    useEffect(() => {
        getCountriesData();
    }, [])

    //Add country
    const addData = async () => {
        try {
            if (!selectedCountry) {
                toast.error("Please select country");
                return;
            }
            // console.log("selectedCountry", selectedCountry);
            const data = await AddCountryData(selectedCountry);
            if (data.status == 1) {
                toast.success(data.message);
                getCountry(perPage, currentPage);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    //Get Country
    const getCountry = async (perPage, page) => {
        try {
            const getData = await GetCountry(perPage, page);
            if (getData.status == 1) {
                setData(getData.data);
                if (getData.data.length < perPage) {
                    setHasMoreData(false);
                } else {
                    setHasMoreData(true);
                }
                setTotalPage(Math.ceil(getData.total_country / perPage));
            }
            setLoading(false);
        } catch (error) {
            toast.error(error);
        }
    }

    useEffect(() => {
        getCountry(perPage, currentPage);
    }, [currentPage])


    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        setCurrentPage(pageNumber);
        await getCountry(perPage, pageNumber);
    };
    const renderPaginationItems = () => {
        const items = [];
        if (totalPage <= 1) return null;

        items.push(
            <Pagination.Prev
                key="prev"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
            />
        );

        for (let i = 1; i <= totalPage; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        items.push(
            <Pagination.Next
                key="next"
                disabled={currentPage === totalPage}
                onClick={() => handlePageChange(currentPage + 1)}
            />
        );

        return items;
    };


    // Delete country
    const handleDeleteModalShow = async (notifyId) => {
        setDeleteModal(true);
        setId(notifyId);
    }
    const handleDeleteModalClose = async () => {
        setDeleteModal(false);
    }

    const handleDelete = async () => {
        try {
            const deleteData = await DeleteCountry(id);
            if (deleteData.status == 1) {
                toast.success(deleteData.message);
                getCountry();
                setDeleteModal(false);
            } else {
                toast.error(deleteData.message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    return (
        <Layout>
            <div className="border-green">
                <div className="p-3 p-sm-4">
                    <div className="row mb-3">
                        <div className="col-lg-12 col-sm-12">
                            <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                                <select className='search-option'
                                    value={selectedCountry}
                                    onChange={(e) => selectCountryHandler(e.target.value)} >
                                    <option value="" disabled>
                                        Select country to add
                                    </option>

                                    {country?.length &&
                                        country.map(({ name, countryCode }) => (
                                            <option key={countryCode} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                </select>

                                <Button className="fees-btn d-flex align-items-center gap-2 green-btn" onClick={addData} >
                                    <img src="./img/add.svg" alt="Add" width="12px" />
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Table responsive bordered className="mb-0">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Country Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <SpinnerComp />
                                ) : (
                                    <>
                                        {data && data.length > 0 ? (
                                            data.map((country, index) => {
                                                return <tr key={index}>
                                                    <td>{startIndex + index}</td>
                                                    <td>{country.country_name}</td>
                                                    <td>
                                                        <div className="user-tools d-flex align-items-center gap-2 justify-content-center">
                                                            <TooltipComponent
                                                                triggerElement={
                                                                    <Button onClick={() => handleDeleteModalShow(country._id)}>
                                                                        <img src="./img/profile/delete.svg" alt="" />
                                                                    </Button>
                                                                }
                                                                tooltipContent="Delete Country" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="10" style={{ fontSize: 'large' }}>No Data Found</td>
                                            </tr>
                                        )
                                        }
                                    </>
                                )
                            }
                        </tbody>
                    </Table>

                    {totalPage > 1 && (
                        <Pagination>{renderPaginationItems()}</Pagination>
                    )}
                </div>
            </div>

            {/* Delete Modal */}
            <Modal
                show={deleteModal}
                onHide={handleDeleteModalClose}
                centered
                scrollable
                className="edit-user delete-user"
            >
                <Modal.Body className="py-4">
                    <div className="d-flex justify-content-center position-relative">
                        <h5 className="modal-title-text modal-border pb-3 text-center w-100">
                            Delete Country
                        </h5>
                    </div>
                    <div className="text-center mt-4">
                        <p className="my-2">Are you sure you want to delete this country?</p>
                        <div className="d-flex align-items-center gap-2 mt-4 px-2">
                            <Button className="delete-tools" onClick={handleDeleteModalClose}>
                                Cancel
                            </Button>
                            <Button className="delete-tools active" onClick={() => handleDelete(id)}>Delete Country</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </Layout >
    )
}

export default AddCountry
