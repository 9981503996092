import React, { Fragment } from 'react'

const Error = () => {

  return (
    <Fragment>
      <section className='auth-main1 d-flex align-items-center justify-content-center'>
        <div className='auth-box1'>
          <div>
            <img src='./img/logo.svg' width="150px" className='logo' />
            <div className='auth-heading1 position-relative my-5'>
              <h1>404</h1>
              <p className='auth-text1' style={{ fontSize: '22px' }}>Oops! Page not found</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default Error
