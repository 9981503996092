import React, { useState } from "react";
import Layout from "../layout/Layout";
import TripData from "../components/TripData";
import { useLocation } from "react-router-dom";

const TripDetails = () => {
  const [searchData, setSearchData] = useState('');
  const location = useLocation();
  const [selectedStatus, setSelectedStatus] = useState(location?.state?.selectedStatus || 'All');

  const handleChange = async (e) => {
    const inputValue = e.target.value;
    setSearchData(inputValue);

    // Check if the search input is "Done" and set selectedStatus accordingly
    if (inputValue === 'done') {
      setSelectedStatus('Done');
    } else if (inputValue === 'pending') {
      setSelectedStatus('Pending');
    }
    else if (inputValue === 'canceled' || inputValue === 'cancel') {
      setSelectedStatus('Cancel');
    }
    else if (inputValue === 'upcoming') {
      setSelectedStatus('Upcoming');
    }
    else if (inputValue === 'inprogress') {
      setSelectedStatus('In progress');
    }
    else if (inputValue === 'reject' || inputValue === 'rejected') {
      setSelectedStatus('Reject');
    }
    else {
      setSelectedStatus('All');
    }
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <Layout>
      <div className="border-green">
        <div className="p-3 p-sm-4">
          <div className="row mb-3">
            <div className="col-lg-12 col-sm-12">
              <div className="d-flex align-content-center justify-content-between gap-3 flex-wrap">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search Driver/User name..."
                  style={{ maxWidth: "400px" }}
                  value={searchData} onChange={handleChange}
                  name="searchData"
                />
                <select className="search-option" onChange={handleStatusChange} value={selectedStatus} >
                  <option value="All">All</option>
                  <option value="done">Done</option>
                  <option value="inprogress">In progress</option>
                  <option value="pending">Pending</option>
                  <option value="canceled">Cancel</option>
                  <option value="upcoming">Upcoming</option>
                  <option value="rejected">Reject</option>
                </select>
              </div>
            </div>
          </div>
          <TripData search={searchData} selectedStatus={selectedStatus} />
        </div>
      </div>
    </Layout>
  );
};

export default TripDetails;
